const createGuid = () => {
  // https://gist.github.com/gordonbrander/2230317
  return (
    "_" +
    (
      Date.now().toString(36) +
      Math.random()
        .toString(36)
        .substr(2, 5)
    ).toUpperCase()
  );
};

export { createGuid };
