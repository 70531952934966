import React from "react";
import MaterialTable from "material-table";

import "./leads.css";

const Leads = ({ userData, onUserDataChange }) => {
  // const handleEdit = ({ leadID, key, update }) => {
  //   const updatedLeads = userData.leads.map(lead =>
  //     lead._id === leadID
  //       ? {
  //           ...lead,
  //           [key]: update
  //         }
  //       : lead
  //   );
  //   onUserDataChange({ key: "leads", value: updatedLeads });
  // };

  // const addLead = () => {
  //   const updatedLeads = [
  //     { _id: "new", name: "", email: "", phone: "" },
  //     ...userData.leads
  //   ];
  //   onUserDataChange({ key: "leads", value: updatedLeads });
  // };
  const leads =
    userData && userData.leads
      ? userData.leads.map(lead => ({
          ...lead,
          dateContact: new Date(parseInt(lead ? lead.created : 0))
        }))
      : [];

  console.log(leads);

  return (
    <div
      className="box-content-center"
      id="lead-view"
      style={{ maxWidth: "100%" }}
    >
      <MaterialTable
        id="leads"
        columns={[
          { title: "Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Created", field: "dateContact", type: "date" }
        ]}
        data={leads}
        title="Leads"
        options={{
          exportButton: true,
          exportFileName: "pipeline-pro-tools-leads"
        }}
      ></MaterialTable>
    </div>
  );
};

export default Leads;
