import React from "react";

const PptAction = ({ label, icon, className = "", onClick, id = "" }) => (
  <button className={`ppt-action ${className}`} id={id} onClick={onClick}>
    {icon()}
    {label ? <span className="ppt-action-label">{label}</span> : null}
  </button>
);

export default PptAction;
