import React from "react";
import ClearIcon from "@material-ui/icons/Clear";

import "./keywords.css";

const Keywords = ({ tags = [], onDelete, onAdd }) => (
  <div className="keyword-container">
    <div className="keyword-add"></div>
    <div className="keyword-wrapper">
      {tags.map((tag, index) => (
        <span
          key={index}
          className="keyword-item"
          onClick={() => onDelete(tag)}
        >
          {tag}
          <ClearIcon></ClearIcon>
        </span>
      ))}
      <input
        className="keyword-input"
        onKeyUp={e => {
          if (e.key === "Enter" || e.key === ",") {
            onAdd(e.target.value);
            e.target.value = "";
          }
        }}
        onKeyDown={e => {
          if (e.key === "Tab") {
            e.preventDefault();
            onAdd(e.target.value);
            e.target.value = "";
          }
        }}
      ></input>
    </div>
  </div>
);

export default Keywords;
