import React, { useMemo } from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
import leadsBetweenDates from "../../helpers/leadsBetweenDates";

const leadsOfType = ({ leads, type }) =>
  leads.reduce(
    (count, lead) => (lead.sourceType === type ? count + 1 : count),
    0
  );

const simpleDateString = date =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const LeadsOverTime = ({ leads }) => {
  const leads30Days = useMemo(() => {
    if (!leads) return [];
    const endDate = new Date().valueOf();
    const startDate = endDate - 30 * 24 * 60 * 60 * 1000; // 30 days ago
    const threeDays = 259200000;
    // imperative since there's not a clean date array for us to start with
    let result = [];
    for (
      let iteratorDate = startDate;
      iteratorDate <= endDate;
      iteratorDate += threeDays
    ) {
      const leadsToDate = leadsBetweenDates({
        leads,
        startDate,
        endDate: iteratorDate
      });
      result.push({
        graphDate: simpleDateString(new Date(iteratorDate)),
        unknown: leadsOfType({ leads: leadsToDate, type: "unknown" }),
        sms: leadsOfType({ leads: leadsToDate, type: "sms" }),
        voice: leadsOfType({ leads: leadsToDate, type: "voice" }),
        listing: leadsOfType({ leads: leadsToDate, type: "listing" }),
        web: leadsOfType({ leads: leadsToDate, type: "web" })
      });
    }
    return result;
  }, [leads]);

  return (
    <AreaChart
      width={600}
      height={200}
      data={leads30Days}
      margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
    >
      <XAxis dataKey="graphDate"></XAxis>
      <YAxis></YAxis>
      <Tooltip></Tooltip>
      <Area
        type="monotone"
        dataKey="unknown"
        stackId="1"
        fill="#0064b669"
      ></Area>
      <Area type="monotone" dataKey="sms" stackId="1" fill="#F52C4B"></Area>
      <Area type="monotone" dataKey="voice" stackId="1" fill="#0060AD"></Area>
      <Area type="monotone" dataKey="listing" stackId="1" fill="#5DA964"></Area>
      <Area type="monotone" dataKey="web" stackId="1" fill="#F98D17"></Area>
    </AreaChart>
  );
};

export default LeadsOverTime;
