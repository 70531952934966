import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { createGuid } from "../../../helpers/guidHelper";

const GoogleMapsImporter = ({ onDismiss, onSubmit, onResult }) => {
  const [autoComplete, setAutoComplete] = useState(null);

  const handleAutoCompleteLoad = loadedScript => {
    setAutoComplete(loadedScript);
  };

  const handlePlaceChanged = () => {
    if (!autoComplete) return;
    const {
      address_components,
      name,
      place_id
      // vicinity,
      // url
    } = autoComplete.getPlace();

    const result = {
      address: name,
      googlePlaceId: place_id,
      city: address_components.find(component =>
        component.types.includes("locality")
      ).short_name,
      state: address_components.find(component =>
        component.types.includes("administrative_area_level_1")
      ).short_name,
      zip: address_components.find(component =>
        component.types.includes("postal_code")
      ).short_name
    };

    if (!onResult) {
      console.log(
        "Google Maps Importer needs a place select handler, instead we're just going to dump our data here."
      );
      console.table(result);
      return;
    }
    onResult(result);
  };

  return (
    <form
      className="box-content panel-content flex-center-column position-rel"
      onSubmit={onSubmit}
      id="google-maps-importer-form"
    >
      <Autocomplete
        onLoad={handleAutoCompleteLoad}
        onPlaceChanged={handlePlaceChanged}
      >
        <label
          className="ppt-input-container w90"
          htmlFor="google-places-address"
        >
          <span className="ppt-input-label">Address</span>
          <input
            className="ppt-input w90 transform-up-half-rem"
            placeholder="123 Main St."
            name="google-places-address"
            autoComplete={createGuid()}
          ></input>
        </label>
      </Autocomplete>
      <button
        className="abs-top-left-1rem background-transparent"
        onClick={onDismiss}
        type="button"
      >
        <span aria-label="close" role="img">
          ❌
        </span>
      </button>
    </form>
  );
};

export default GoogleMapsImporter;
