import { apiGetSignedS3Url } from "./api/apiSignedS3Url";

const sessionKey = key => `ppt-${key}`;

const getS3Url = async ({ key }) => {
  const cachedKey = sessionStorage.getItem(sessionKey(key));

  if (!cachedKey) {
    const result = await apiGetSignedS3Url({ key });
    sessionStorage.setItem(sessionKey(key), result);
    return result;
  }

  return cachedKey;
};

export { getS3Url };
