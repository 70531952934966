import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";

const CallToAction = ({
  callToAction,
  callToAction2,
  onChange,
  onChange2,
  onSubmit
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div
      id="website-cta-wrapper"
      className="box-content padding-1rem flex-center-column w42rem margin1rem-top position-rel"
    >
      {editMode ? (
        <>
          <input
            onChange={onChange}
            value={callToAction}
            id="cta-edit-mode-input"
          ></input>
          <input
            onChange={onChange2}
            value={callToAction2}
            id="cta2-edit-mode-input"
          ></input>
        </>
      ) : (
        <>
          <h2 id="website-cta-text">{callToAction}</h2>
          <h3 id="website-cta2-text">{callToAction2}</h3>
        </>
      )}
      {editMode ? (
        <button
          className="text-small lead-magnet-action abs-top-right blue-text"
          onClick={() => {
            setEditMode(false);
            onSubmit({ callToAction });
          }}
        >
          <EditIcon></EditIcon>
          <span>Save</span>
        </button>
      ) : (
        <button
          className="text-small lead-magnet-action abs-top-right"
          onClick={() => setEditMode(true)}
        >
          <EditIcon></EditIcon>
          <span>Edit</span>
        </button>
      )}
    </div>
  );
};

export default CallToAction;
