const friendlyPhone = number =>
  `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8, 12)}`;

const generateAd = ({
  listing: {
    uniqueName: listingName,
    beds = 1,
    fullBaths = 1,
    halfBaths = 0,
    description
  },
  voiceLeadMagnet: { phoneNumber: voiceNumber, code: voiceCode },
  smsLeadMagnet: { phoneNumber: smsNumber, code: smsCode },
  webLM: { uniqueName: subdomain = "www" }
}) => `${beds} bed, ${fullBaths + halfBaths} bath
${description}
<h3>To get more details (current pricing and photos) about this property and to search all properties in the area for free, go to my website:
https://${subdomain}.onehomesearch.site/${listingName}

OR call our 24/7 information hotline at ${friendlyPhone(
  voiceNumber
)} and enter ${voiceCode}.

OR for up-to-date pricing and photos text the code "${smsCode}" to ${friendlyPhone(
  smsNumber
)}.</h3>`;

export default generateAd;
