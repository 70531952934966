const leadsBetweenDates = ({ leads, startDate, endDate }) => {
  const start = parseInt(startDate);
  const end = parseInt(endDate);
  return leads.filter(lead => {
    const created = parseInt(lead.created);
    return created >= start && created <= end;
  });
};

export default leadsBetweenDates;
