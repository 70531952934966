import apiAddress from "./apiAddress";

const listingGql = listingID => `listingID:"${listingID}"`;

const apiUpdateTwilioLM = async ({
  token,
  id,
  responseText,
  phoneNumber,
  code,
  listingID
}) => {
  const mutation = `mutation {
        updateTwilioLeadMagnet(id:"${id}", data:{
          responseText:"${responseText}"
          phoneNumber:"${phoneNumber}"
          code:"${code}"
          ${listingID ? listingGql(listingID) : ""}
        }) {
          _id
          phoneNumber
          code
          listingID
          responseText
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.updateTwilioLeadMagnet;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateTwilioLM;
