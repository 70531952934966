import React from "react";
import CampaignCard from "./CampaignCard";

import AddBoxIcon from "@material-ui/icons/AddBox";

import { getToken } from "../../helpers/tokenManager";
import apiArchiveCampaign from "../../helpers/api/apiArchiveCampaign";

const Campaigns = ({ userData, onUserDataChange, appState, onStateChange }) => {
  const handleLoadCampaign = ({ id }) => {
    onStateChange({
      appKey: "ad-wizard",
      newState: {
        campaign: id,
        panel: "wizard"
      },
      navLink: "ad-wizard"
    });
  };

  const handleArchive = async ({ id }) => {
    try {
      // will throw error, no need to handle directly
      await apiArchiveCampaign({
        token: getToken(),
        id
      });
      onUserDataChange({
        key: "campaigns",
        value: userData.campaigns.filter(campaign => campaign._id !== id)
      });
    } catch (error) {
      onStateChange({
        appKey: "messages",
        newState: [
          ...appState.messages,
          {
            type: "error",
            id: "campaign-archive-error",
            message: error.message
          }
        ]
      });
    }
  };

  return (
    <div className="flex-center-column w90">
      <div
        id="create-new-ad"
        className="box-content w20rem flex-row-center padding-1rem"
        onClick={() =>
          onStateChange({
            appKey: "ad-wizard",
            newState: {
              ...appState["ad-wizard"],
              panel: "wizard"
            }
          })
        }
      >
        <button className="action-button  blue-text">
          <AddBoxIcon></AddBoxIcon>
          create new ad
        </button>
      </div>
      {userData.campaigns.map(campaign => (
        <CampaignCard
          campaignID={campaign._id}
          userData={userData}
          key={campaign._id}
          onLoad={handleLoadCampaign}
          onArchive={handleArchive}
        ></CampaignCard>
      ))}
    </div>
  );
};

export default Campaigns;
