import React, { useState, useEffect } from "react";
import { getS3Url } from "../../../helpers/s3Cache";

const ListingCardImage = ({ images = [], index, onClick }) => {
  const [signedUrls, setSignedUrls] = useState([]);
  useEffect(() => {
    const getSignedUrls = async () => {
      if (!images.length) return [];
      const promises = images.map(
        async image => await getS3Url({ key: image.keyName })
      );
      const urls = await Promise.all(promises);
      setSignedUrls(urls);
      return () => setSignedUrls([]);
    };
    getSignedUrls();
  }, [images]);

  return (
    <div className="listing-card-image-wrap" onClick={onClick}>
      {signedUrls[index] && signedUrls[index] !== "null" ? (
        <img
          src={signedUrls[index]}
          alt="Listing"
          className="listing-card-image"
        ></img>
      ) : (
        <div className="listing-card-image-placeholder"></div>
      )}
    </div>
  );
};

export default ListingCardImage;
