import React from "react";

const PptCheckbox = ({
  name = "",
  onChange,
  labelText = "checkbox",
  isChecked = false,
  className = ""
}) => {
  return (
    <div className={`ppt-checkbox box-shadow-subtle ${className}`}>
      <input
        className="ppt-checkbox-input"
        type="checkbox"
        id={name}
        name={name}
        onChange={onChange}
        checked={isChecked}
      ></input>
      <label htmlFor={name} className="ppt-checkbox-label">
        {labelText}
      </label>
    </div>
  );
};

export default PptCheckbox;
