import React from "react";

import Dashboard from "../apps/Dashboard/Dashboard";
import Leads from "../apps/Leads/Leads";
import LeadMagnets from "../apps/LeadMagnets/LeadMagnets";
import WebLeads from "../apps/WebLeads/WebLeads";
import Listings from "../apps/Listings/Listings";
import Ads from "../apps/Ads/Ads";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ListAltIcon from "@material-ui/icons/ListAlt";
import WebIcon from "@material-ui/icons/Web";
import SmsIcon from "@material-ui/icons/Sms";
import PhoneIcon from "@material-ui/icons/Phone";

const appData = [
  {
    appKey: "dashboard",
    appName: "dashboard",
    appMain: props => <Dashboard {...props}></Dashboard>,
    appIcon: () => <DashboardIcon></DashboardIcon>
  },
  {
    appKey: "leads",
    appName: "leads",
    appMain: props => <Leads {...props}></Leads>,
    appIcon: () => <PeopleAltIcon></PeopleAltIcon>
  },
  {
    appKey: "listings",
    appName: "listings",
    appMain: props => <Listings {...props}></Listings>,
    appIcon: () => <HomeWorkIcon></HomeWorkIcon>
  },
  {
    appKey: "ad-wizard",
    appName: "ads",
    appMain: props => <Ads {...props}></Ads>,
    appIcon: () => <ListAltIcon></ListAltIcon>
  },
  {
    appKey: "web",
    appName: "web site",
    appMain: props => <WebLeads {...props}></WebLeads>,
    appIcon: () => <WebIcon></WebIcon>
  },
  {
    appKey: "sms-magnets",
    appName: "sms",
    appMain: props => <LeadMagnets {...props} lmType="sms"></LeadMagnets>,
    appIcon: () => <SmsIcon></SmsIcon>
  },
  {
    appKey: "phone-magnets",
    appName: "voice",
    appMain: props => <LeadMagnets {...props} lmType="voice"></LeadMagnets>,
    appIcon: () => <PhoneIcon></PhoneIcon>
  }
];

export { appData };
