import React from "react";
import ListingView from "./ListingView/ListingView";
import ListingEditor from "./ListingEditor/ListingEditor";

const Listings = ({ appState, onStateChange, onUserDataChange, userData }) => {
  return (
    <>
      {appState && appState.listing.panel === "listing-view" ? (
        <ListingView
          appState={appState}
          onStateChange={onStateChange}
          onUserDataChange={onUserDataChange}
          userData={userData}
        ></ListingView>
      ) : null}
      {appState && appState.listing.panel === "listing-editor" ? (
        <ListingEditor
          appState={appState}
          onStateChange={onStateChange}
          onUserDataChange={onUserDataChange}
          userData={userData}
          editingListing={appState ? appState.listing["editing-listing"] : null}
        ></ListingEditor>
      ) : null}
    </>
  );
};

export default Listings;
