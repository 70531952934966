import React, { useState, useEffect } from "react";
import PptInput from "../../components/PptInput";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import WarningIcon from "@material-ui/icons/Warning";

const UniqueName = ({
  uniqueName,
  onUniqueNameChange,
  hostAddress,
  createNew,
  isValid,
  onSubmit
}) => {
  const [uniqueNameText, setUniqueNameText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleInput = e => {
    const value = e.target.value;
    clearTimeout(typingTimeout);
    setUniqueNameText(value);
    setTypingTimeout(
      setTimeout(() => {
        onUniqueNameChange({ uniqueName: value });
      }, 1000)
    );
  };

  useEffect(() => {
    if (!uniqueNameText) {
      setUniqueNameText(uniqueName);
    }
  }, [uniqueName, uniqueNameText]);

  return (
    <div className="box-content padding-1rem flex-row-space-evenly w82percent position-rel">
      <PptInput
        onChange={handleInput}
        value={uniqueNameText}
        placeholder="Try 'YourCompany' or 'JaneSmith'"
        name="uniqueName"
        className="w20rem"
        labelText="Your Website Name"
      ></PptInput>
      <span
        className="outside-link"
        onClick={() => {
          if (!uniqueName) return;
          window.open(`https://${uniqueName}.${hostAddress}`, "_blank");
        }}
      >
        .{hostAddress}
      </span>
      <span
        className={
          isValid
            ? "flex-row-center text-small abs-top-right green-text"
            : "flex-row-center text-small abs-top-right red-text"
        }
      >
        {isValid ? "" : "That name is in use. "}
        {isValid ? <CheckBoxIcon></CheckBoxIcon> : <WarningIcon></WarningIcon>}
      </span>
      <button
        className="headline-button-light"
        onClick={() => onSubmit({ uniqueName: uniqueNameText })}
      >
        {createNew ? "create" : "update"}
      </button>
    </div>
  );
};

export default UniqueName;
