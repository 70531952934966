const localStorageKey = "ppt-app-state";

const defaultAppState = {
  listing: {
    "listing-editor": {
      selectedImporter: "zillow"
    },
    "listing-sidebar": ["listing-editor"],
    googleMapsLoaded: false,
    "editing-listing": null,
    panel: "listing-view"
  },
  "ad-wizard": {
    campaign: null,
    panel: "ads"
  },
  leadMagnet: {
    "lead-magnet-sidebar": "lead-magnet-view"
  },
  accountModalOpen: false,
  messages: [
    // {
    //   type: "error",
    //   time: 10,
    //   id: "test-error",
    //   message: "this is what an error looks like."
    // },
    // {
    //   type: "success",
    //   time: 0,
    //   id: "test-success",
    //   message: "woohoo we did it"
    // }
  ]
};

const loadDefaultAppState = ({ reset = false }) => {
  if (reset) {
    localStorage.setItem(localStorageKey, defaultAppState);
    return defaultAppState;
  }
  const localAppState = localStorage.getItem(localStorageKey);
  if (localAppState) return localAppState;
  else {
    localStorage.setItem(localStorageKey, defaultAppState);
    return defaultAppState;
  }
};

export { defaultAppState, loadDefaultAppState };
