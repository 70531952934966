import React from "react";

const PptInput = ({
  labelText = "Input",
  name = "",
  id = null,
  type = "text",
  onChange,
  value,
  placeholder,
  className = "",
  pattern = null,
  autoComplete = "",
  onBlur = null
}) => {
  return (
    <label
      className={`ppt-input-container ${className}`}
      htmlFor={name}
      id={id}
    >
      <span className="ppt-input-label">{labelText}</span>
      <input
        className="ppt-input w100 transform-up-half-rem"
        placeholder={placeholder}
        name={name}
        value={value ? value : ""}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        pattern={pattern ? pattern : null}
        autoComplete={autoComplete}
      ></input>
    </label>
  );
};

export default PptInput;
