import React, { useState, useEffect } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CancelIcon from "@material-ui/icons/Cancel";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MapIcon from "@material-ui/icons/Map";
import PublicIcon from "@material-ui/icons/Public";
import LoadingWrapper from "../../../components/LoadingWrapper";

const TwilioProvisionNumbers = ({
  linkedPhoneNumbers,
  availableLocalNumbers,
  availableTollFreeNumbers,
  onProvisionNumber,
  onReleaseNumber,
  onValidAreaCode
}) => {
  const [confirmDeleteItems, setConfirmDeleteItems] = useState([]);
  const [confirmPurchaseItems, setConfirmPurchaseItems] = useState([]);
  const [searchType, setSearchType] = useState("local");
  const [areaCode, setAreaCode] = useState("");
  const [localNumbersLoading, setLocalNumbersLoading] = useState(false);

  useEffect(() => {
    if (/^\d{3}$/.test(areaCode)) {
      onValidAreaCode(areaCode);
      setLocalNumbersLoading(true);
    }
  }, [areaCode, onValidAreaCode]);

  useEffect(() => {
    setLocalNumbersLoading(false);
  }, [availableLocalNumbers]);

  const linkedPhoneComponents = linkedPhoneNumbers.map(
    ({ friendlyName, phoneNumber }) => (
      <div className="flex-row-space-between w20rem" key={phoneNumber}>
        <div className="lead-magnet-item">
          <span className="lead-magnet-label">Phone Number</span>
          <span className="lead-magnet-value">{friendlyName}</span>
        </div>
        {confirmDeleteItems.some(item => item === phoneNumber) ? (
          <div className="flex-row-center">
            <button
              className="lead-magnet-action"
              onClick={() => onReleaseNumber({ phoneNumber })}
            >
              <DeleteForeverIcon></DeleteForeverIcon>
              <span>Confirm</span>
            </button>
            <button
              className="lead-magnet-action"
              onClick={() =>
                setConfirmDeleteItems(prev =>
                  prev.filter(item => item !== phoneNumber)
                )
              }
            >
              <CancelIcon></CancelIcon>
              <span>Cancel</span>
            </button>
          </div>
        ) : (
          <button
            className="lead-magnet-action"
            onClick={() =>
              setConfirmDeleteItems(prev => [...prev, phoneNumber])
            }
          >
            <DeleteForeverIcon></DeleteForeverIcon>
            <span>Remove</span>
          </button>
        )}
      </div>
    )
  );

  const tollFreeComponents = () =>
    availableTollFreeNumbers.map(({ phoneNumber, friendlyName, cost }) => (
      <div className="flex-row-space-between w100percent" key={phoneNumber}>
        <div className="lead-magnet-item w9rem">
          <span className="lead-magnet-label">Phone Number</span>
          <span className="lead-magnet-value">{friendlyName}</span>
        </div>
        <div className="lead-magnet-item w7rem">
          <span className="lead-magnet-label">Monthly Cost</span>
          <span className="lead-magnet-value">{cost}</span>
        </div>
        <div className="flex-row-center w7rem">
          {confirmPurchaseItems.some(item => item === phoneNumber) ? (
            <>
              <button
                className="lead-magnet-action"
                onClick={() => onProvisionNumber({ phoneNumber })}
              >
                <ShoppingCartIcon></ShoppingCartIcon>
                <span>Confirm</span>
              </button>
              <button
                className="lead-magnet-action"
                onClick={() =>
                  setConfirmPurchaseItems(prev =>
                    prev.filter(item => item !== phoneNumber)
                  )
                }
              >
                <CancelIcon></CancelIcon>
                <span>Cancel</span>
              </button>
            </>
          ) : (
            <button
              className="lead-magnet-action"
              onClick={() =>
                setConfirmPurchaseItems(prev => [...prev, phoneNumber])
              }
            >
              <ShoppingCartIcon></ShoppingCartIcon>
              <span>Purchase</span>
            </button>
          )}
        </div>
      </div>
    ));

  const localPhoneComponents = () => (
    <>
      <div className="flex-row-wrap w100percent">
        <label className="ppt-input-container w5rem" htmlFor="areaCode">
          <span className="ppt-input-label">Area Code</span>
          <input
            className="ppt-input w90 transform-up-half-rem"
            placeholder="123"
            name="areaCode"
            type="text"
            onChange={e => setAreaCode(e.target.value)}
          ></input>
        </label>
        <LoadingWrapper loading={localNumbersLoading}></LoadingWrapper>
      </div>
      {availableLocalNumbers.map(({ friendlyName, phoneNumber, cost }) => (
        <div className="flex-row-space-between w100percent" key={phoneNumber}>
          <div className="lead-magnet-item w9rem">
            <span className="lead-magnet-label">Phone Number</span>
            <span className="lead-magnet-value">{friendlyName}</span>
          </div>
          <div className="lead-magnet-item w7rem">
            <span className="lead-magnet-label">Cost</span>
            <span className="lead-magnet-value">{cost}</span>
          </div>
          <div className="flex-row-center w7rem">
            {confirmPurchaseItems.some(item => item === phoneNumber) ? (
              <>
                <button
                  className="lead-magnet-action"
                  onClick={() => onProvisionNumber({ phoneNumber })}
                >
                  <ShoppingCartIcon></ShoppingCartIcon>
                  <span>Confirm</span>
                </button>
                <button
                  className="lead-magnet-action"
                  onClick={() =>
                    setConfirmPurchaseItems(prev =>
                      prev.filter(item => item !== phoneNumber)
                    )
                  }
                >
                  <CancelIcon></CancelIcon>
                  <span>Cancel</span>
                </button>
              </>
            ) : (
              <button
                className="lead-magnet-action"
                onClick={() =>
                  setConfirmPurchaseItems(prev => [...prev, phoneNumber])
                }
              >
                <ShoppingCartIcon></ShoppingCartIcon>
                <span>Purchase</span>
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className="flex-row-space-evenly w100percent">
      <div className="flex-left-column h20rem">
        <h3>Your Phone Numbers</h3>
        {linkedPhoneComponents}
      </div>
      <div className="h20rem overflow-scroll w30rem">
        <div className="flex-row-space-evenly w100percent">
          <button
            className="lead-magnet-action"
            onClick={() => setSearchType("local")}
          >
            <MapIcon></MapIcon>
            <span>Local</span>
          </button>
          <button
            className="lead-magnet-action"
            onClick={() => setSearchType("toll-free")}
          >
            <PublicIcon></PublicIcon>
            <span>Toll Free</span>
          </button>
        </div>
        {searchType === "local" ? localPhoneComponents() : null}
        {searchType === "toll-free" ? tollFreeComponents() : null}
      </div>
    </div>
  );
};

export default TwilioProvisionNumbers;
