import React, { useState, useEffect, useCallback } from "react";
import ReactModal from "react-modal";
import Main from "../Main/Main";
import MainLoader from "../Main/MainLoader";
import SidebarNav from "../SidebarNav/SidebarNav";
import SidebarNavItem from "../SidebarNav/SidebarNavItem";
import Account from "../Account/Account";
import Messages from "../Messages/Messages";
import { Redirect } from "react-router-dom";
import { isLoggedIn, setAuthCookie } from "../helpers/authHelper";
import { appData } from "../helpers/appData";
import { getToken } from "../helpers/tokenManager";
import { getUser } from "../helpers/api/apiGetUser";
import { loadDefaultAppState } from "../helpers/defaultAppState";
import { LoadScriptNext } from "@react-google-maps/api";
import { googleMapsApiKey, libraries } from "../helpers/googleMapsApiKey";

ReactModal.setAppElement("#root");

function SinglePageFramework({ history }) {
  const [appState, setAppState] = useState(
    loadDefaultAppState({ reset: true })
  );
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("ppt-active-tab") || "dashboard"
  );
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      // weird workaround because linkedPhoneNumbers comes from
      // twilio directly and is part of a new query
      const { user, linkedPhoneNumbers } = await getUser({ token });
      console.log(user);
      setUserData({ ...user, linkedPhoneNumbers });
    };
    fetchData();
  }, []);
  useEffect(() => {
    sessionStorage.setItem("ppt-active-tab", activeTab);
  }, [activeTab]);

  const handleGoogleMapLoad = useCallback(() => {
    setAppState(prev => ({
      ...prev,
      googleMapsLoaded: true
    }));
  }, []);

  if (!isLoggedIn()) return <Redirect to="/login"></Redirect>;

  const handleLogout = () => {
    setAuthCookie(false);

    // todo release token
    history.push("/login");
  };

  const handleNavTabClick = navLink => {
    setActiveTab(navLink);
  };

  const handleAppStateChange = ({
    appKey,
    newState,
    navLink = null,
    message
  }) => {
    if (navLink) {
      setActiveTab(navLink);
    }
    if (message) {
      setAppState(prev => ({
        ...prev,
        messages: [
          ...appState.messages.filter(m => m.id !== message.id),
          message
        ]
      }));
    }
    if (appKey) {
      setAppState(prev => ({
        ...prev,
        [appKey]: newState
      }));
    }
  };

  const handleUserDataChange = ({ key, value }) => {
    setUserData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <div id="root-app">
      <LoadScriptNext
        googleMapsApiKey={googleMapsApiKey}
        libraries={libraries}
        onLoad={handleGoogleMapLoad}
      >
        <ReactModal
          isOpen={appState && appState.accountModalOpen}
          contentLabel="Account"
          onRequestClose={() =>
            handleAppStateChange({
              appKey: "accountModalOpen",
              newState: false
            })
          }
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          overlayClassName="glass"
          className="abs-center box-content-modal rounded-corner"
        >
          <Account
            userData={userData}
            onUserDataChange={handleUserDataChange}
            onDismiss={() =>
              handleAppStateChange({
                appKey: "accountModalOpen",
                newState: false
              })
            }
            onSave={() =>
              handleAppStateChange({
                appKey: "accountModalOpen",
                newState: false
              })
            }
          ></Account>
        </ReactModal>
        <div id="nav-actions">
          <button
            id="account-button"
            className="headline-button"
            onClick={() =>
              handleAppStateChange({
                appKey: "accountModalOpen",
                newState: true
              })
            }
          >
            account
          </button>
          <button
            id="logout-button"
            className="headline-button-secondary"
            onClick={handleLogout}
          >
            logout
          </button>
        </div>
        <SidebarNav>
          {appData.map(app => (
            <SidebarNavItem
              key={app.appKey}
              activeTab={activeTab}
              onClick={handleNavTabClick}
              navLink={app.appKey}
            >
              {app.appName}
              <span className="margin-icon blue-text flex-row-center">
                {app.appIcon()}
              </span>
            </SidebarNavItem>
          ))}
        </SidebarNav>
        <Main activeTab={activeTab}>
          <MainLoader
            activeTab={activeTab}
            appState={appState}
            onStateChange={handleAppStateChange}
            userData={userData}
            onUserDataChange={handleUserDataChange}
          ></MainLoader>
        </Main>
        <Messages
          appState={appState}
          onStateChange={handleAppStateChange}
        ></Messages>
      </LoadScriptNext>
    </div>
  );
}

export default SinglePageFramework;
