const fetchWithToken = async ({ token, query, address }) => {
  try {
    const result = await fetch(address, {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    return json.data;
  } catch (error) {
    throw error;
  }
};

export { fetchWithToken };
