import React, { useState, useEffect, useCallback } from "react";
import TwilioAccountConnect from "./TwilioAccountConnect";
import TwilioProvisionNumbers from "./TwilioProvisionNumbers";
import CustomGenerate from "./CustomGenerate";
import apiLinkedPhoneNumbers from "../../../helpers/api/apiLinkedPhoneNumbers";
import apiSearchLocalPhoneNumbers from "../../../helpers/api/apiSearchLocalPhoneNumbers";
import apiSearchTollFreePhoneNumbers from "../../../helpers/api/apiSearchTollFreePhoneNumbers";
import apiProvisionNumber from "../../../helpers/api/apiProvisionNumber";
import apiReleasePhoneNumber from "../../../helpers/api/apiReleasePhoneNumber";
import apiCreateTwilioLM from "../../../helpers/api/apiCreateTwilioLM";
import { getToken } from "../../../helpers/tokenManager";
import asyncParseJSON from "../../../helpers/asyncParseJSON";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SettingsPhoneIcon from "@material-ui/icons/SettingsPhone";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const CustomLeadMagnet = ({
  appState,
  onStateChange,
  userData,
  onUserDataChange,
  onBack,
  lmType
}) => {
  const [viewState, setViewState] = useState("");
  const [areaCodeSearch, setAreaCodeSearch] = useState("");
  const [availableLocalNumbers, setAvailableLocalNumbers] = useState([]);
  const [availableTollFreeNumbers, setAvailableTollFreeNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  const sendErrorMessage = useCallback(
    (message, id = "custom-lead-magnet-error") => {
      if (appState.messages.some(message => message.id === "lead-magnet-error"))
        return;
      onStateChange({
        message: {
          id,
          type: "error",
          message
        }
      });
    },
    [appState.messages, onStateChange]
  );

  // todo : api callbacks for provision / remove
  // todo : customized code generator

  const refreshLinkedPhoneNumbers = async () => {
    try {
      const linkedPhoneNumbers = await apiLinkedPhoneNumbers({
        token: getToken()
      });
      onUserDataChange({
        key: "linkedPhoneNumbers",
        value: linkedPhoneNumbers
      });
    } catch (error) {
      sendErrorMessage(error.message, "linked-phone-error");
    }
  };

  useEffect(() => {
    if (!userData) return;
    if (!userData.twilioAccountConnected) {
      setViewState("connect");
      return;
    }
    if (!userData.linkedPhoneNumbers.length) {
      setViewState("provision");
      return;
    }
    setViewState("generate");
  }, [userData]);

  useEffect(() => {
    if (
      !areaCodeSearch ||
      appState.messages.some(message => message.id === "local-phone-search")
    )
      return;
    const run = async () => {
      try {
        const result = await apiSearchLocalPhoneNumbers({
          token: getToken(),
          areaCode: areaCodeSearch
        });
        setAvailableLocalNumbers(result);
      } catch (error) {
        sendErrorMessage(error.message, "local-phone-search");
        setLoading(false);
      }
    };
    run();
  }, [areaCodeSearch, sendErrorMessage, appState.messages]);

  useEffect(() => {
    const run = async () => {
      if (
        appState.messages.some(
          message => message.id === "tollfree-phone-search"
        )
      )
        try {
          const result = await apiSearchTollFreePhoneNumbers({
            token: getToken()
          });
          setAvailableTollFreeNumbers(result);
        } catch (error) {
          sendErrorMessage(error.message, "tollfree-phone-search");
          setLoading(false);
        }
    };
    run();
  }, [sendErrorMessage, appState.messages]);

  const handleProvisionNumber = async ({ phoneNumber }) => {
    try {
      const result = await apiProvisionNumber({
        token: getToken(),
        phoneNumber
      });
      // todo add a success message thing sometime
      const item = await asyncParseJSON(result.payload);
      onUserDataChange({
        key: "linkedPhoneNumbers",
        value: [...userData.linkedPhoneNumbers, item]
      });
    } catch (error) {
      sendErrorMessage(error.message);
    }
  };

  const handleReleaseNumber = async ({ phoneNumber }) => {
    try {
      await apiReleasePhoneNumber({ token: getToken(), phoneNumber });
      onUserDataChange({
        key: "linkedPhoneNumbers",
        value: [
          ...userData.linkedPhoneNumbers.filter(
            item => item.phoneNumber !== phoneNumber
          )
        ]
      });
      // todo purge any linked lead magnets too
    } catch (error) {
      sendErrorMessage(error.message);
    }
  };

  const handleGenerateLeadMagnet = async ({
    code,
    selectedNumber: { phoneNumber }
  }) => {
    try {
      if (loading) return;
      setLoading(true);
      const result = await apiCreateTwilioLM({
        token: getToken(),
        phoneNumber,
        code,
        userID: userData._id,
        listingID: null,
        responseText: "",
        notify: {
          email: userData.email,
          sms: ""
        },
        lmType
      });
      onUserDataChange({
        key: "twilioLMs",
        value: [result, ...userData.twilioLMs]
      });
      setLoading(false);
    } catch (error) {
      sendErrorMessage(error.message);
    }
  };

  return (
    <>
      <div className="box-content padding-1rem flex-center-column w82percent">
        <nav className="flex-row-space-evenly w50percent">
          <button className="lead-magnet-action" onClick={onBack}>
            <ArrowBackIcon></ArrowBackIcon>
            <span>Back</span>
          </button>
          <button
            className="lead-magnet-action"
            onClick={() => setViewState("connect")}
          >
            <SettingsEthernetIcon></SettingsEthernetIcon>
            <span>Connect</span>
          </button>
          <button
            className="lead-magnet-action"
            onClick={() => setViewState("provision")}
          >
            <SettingsPhoneIcon></SettingsPhoneIcon>
            <span>Numbers</span>
          </button>
          <button
            className="lead-magnet-action"
            onClick={() => setViewState("generate")}
          >
            <AddCircleIcon></AddCircleIcon>
            <span>Generate</span>
          </button>
        </nav>
        {viewState === "connect" ? (
          <TwilioAccountConnect
            onConnect={refreshLinkedPhoneNumbers}
            userIsConnected={userData.twilioAccountConnected}
          ></TwilioAccountConnect>
        ) : null}
        {viewState === "provision" ? (
          <TwilioProvisionNumbers
            linkedPhoneNumbers={userData.linkedPhoneNumbers}
            availableLocalNumbers={availableLocalNumbers}
            availableTollFreeNumbers={availableTollFreeNumbers}
            onValidAreaCode={areaCode => setAreaCodeSearch(areaCode)}
            onProvisionNumber={handleProvisionNumber}
            onReleaseNumber={handleReleaseNumber}
          ></TwilioProvisionNumbers>
        ) : null}
        {viewState === "generate" ? (
          <CustomGenerate
            linkedPhoneNumbers={userData.linkedPhoneNumbers}
            onGenerateLeadMagnet={handleGenerateLeadMagnet}
            loading={loading}
          ></CustomGenerate>
        ) : null}
      </div>
    </>
  );
};

export default CustomLeadMagnet;
