import apiAddress from "./apiAddress";

const apiReleasePhoneNumber = async ({ token, phoneNumber }) => {
  const mutation = `mutation {
        releasePhoneNumber(phoneNumber:"${phoneNumber}"){
          error
          message
          code
          payload
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.releasePhoneNumber;
  } catch (error) {
    throw error;
  }
};

export default apiReleasePhoneNumber;
