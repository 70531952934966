import React from "react";
import MessageCard from "./MessageCard";

import "./messages.css";

const Messages = ({ appState, onStateChange }) => (
  <section id="messages">
    {appState
      ? appState.messages.map((message, index) => (
          <MessageCard
            {...message}
            key={index}
            onClick={() =>
              onStateChange({
                appKey: "messages",
                newState: [
                  ...appState.messages.filter(m => m.id !== message.id)
                ]
              })
            }
          ></MessageCard>
        ))
      : null}
  </section>
);

export default Messages;
