import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiUpdateAgentLogo = async ({ key, token }) => {
  const mutation = `mutation {
        data: updateUser(userUpdate:{
          agentLogo:{
            bucketName:"ppt"
            keyName:"${key}"
          }
        }){
          error
          message
          code
          id
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    if (result.data.error) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateAgentLogo;
