import apiAddress from "./apiAddress";

const apiSeachTollFreePhoneNumbers = async ({ token }) => {
  const query = `{
        searchTollFreePhoneNumbers {
          phoneNumber
          friendlyName
          cost
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    return json.data.searchTollFreePhoneNumbers;
  } catch (error) {
    throw error;
  }
};

export default apiSeachTollFreePhoneNumbers;
