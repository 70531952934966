import React from "react";

const PptItem = ({
  label,
  value,
  className = "",
  labelClass = "",
  valueClass = ""
}) => {
  return (
    <div className={`lead-magnet-item ${className}`}>
      <span className={`lead-magnet-label ${labelClass}`}>{label}</span>
      <span className={`lead-magnet-value ${valueClass}`}>{value}</span>
    </div>
  );
};

export default PptItem;
