import React, { useState, useEffect } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import KeyboardIcon from "@material-ui/icons/Keyboard";

const AgentStory = ({ titleStory, onTitleStoryChange, isSaving = false }) => {
  const [storyText, setStoryText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    setStoryText(prev => (prev ? prev : titleStory));
  }, [titleStory]);

  const handleInput = e => {
    const value = e.target.value;
    clearTimeout(typingTimeout);
    setStoryText(value);
    setIsTyping(true);
    setTypingTimeout(
      setTimeout(() => {
        onTitleStoryChange({ titleStory: value });
        setIsTyping(false);
      }, 1000)
    );
  };

  const renderStatus = () => {
    if (isSaving) {
      return (
        <span className="abs-top-right green-text text-small flex-row-center">
          {"Saving..."}
          <AutorenewIcon></AutorenewIcon>
        </span>
      );
    } else if (isTyping) {
      return (
        <span className="abs-top-right blue-text text-small flex-row-center">
          {"Typing..."}
          <KeyboardIcon></KeyboardIcon>
        </span>
      );
    } else {
      return (
        <span className="abs-top-right green-text text-small flex-row-center">
          {"Saved "}
          <CheckBoxIcon></CheckBoxIcon>
        </span>
      );
    }
  };

  return (
    <div className="box-content flex-center-column w42rem margin1rem-top padding-1rem position-rel">
      <div className="ppt-input-container margin-reset w90percent">
        <h2 className="margin1rem text-align-center w100percent">
          Agent Story
        </h2>
        <textarea
          className="ppt-text-area transform-up-half-rem w100percent"
          rows="15"
          value={storyText}
          onChange={handleInput}
        ></textarea>
      </div>
      {renderStatus()}
    </div>
  );
};

export default AgentStory;
