import React from "react";
import PptInput from "../../../components/PptInput";
import PptTextArea from "../../../components/PptTextArea";

const ListingForm = ({ formData, onFormDataChange, onSubmit, actionText }) => {
  return (
    <form
      className="box-content flex-center-column padding-1rem position-rel margin1rem w100"
      onSubmit={onSubmit}
      id="listing-input-form"
    >
      <div className="flex-row-wrap w90">
        <PptInput
          labelText="Unique Listing Name"
          name="uniqueName"
          onChange={onFormDataChange}
          value={formData.uniqueName}
          placeholder="A Unique Listing Name"
          className="w16rem"
        ></PptInput>
        <PptInput
          labelText="Street Address"
          name="address"
          onChange={onFormDataChange}
          value={formData.address}
          placeholder="123 Main St."
          className="w20rem"
        ></PptInput>
        <PptInput
          labelText="City"
          name="city"
          onChange={onFormDataChange}
          value={formData.city}
          placeholder="City"
          className="w16rem"
        ></PptInput>
        <PptInput
          labelText="State"
          name="state"
          onChange={onFormDataChange}
          value={formData.state}
          placeholder="State"
          className="w5rem"
        ></PptInput>
        <PptInput
          labelText="Zip Code"
          name="zip"
          onChange={onFormDataChange}
          value={formData.zip}
          placeholder="Zip Code"
          className="w7rem"
        ></PptInput>
        <PptInput
          labelText="Price"
          name="price"
          onChange={onFormDataChange}
          value={formData.price}
          placeholder="Price"
          className="w10rem"
          pattern="^[0-9]*$"
        ></PptInput>
        <PptInput
          labelText="Beds"
          name="beds"
          onChange={onFormDataChange}
          value={formData.beds}
          placeholder="1"
          type="number"
          className="w7rem"
        ></PptInput>
        <PptInput
          labelText="Full Baths"
          name="fullBaths"
          onChange={onFormDataChange}
          value={formData.fullBaths}
          placeholder="1"
          type="number"
          className="w7rem"
        ></PptInput>
        <PptInput
          labelText="Half Baths"
          name="halfBaths"
          onChange={onFormDataChange}
          value={formData.halfBaths}
          placeholder="0"
          type="number"
          className="w7rem"
        ></PptInput>
      </div>
      <div className="w90">
        <PptTextArea
          labelText="Description"
          name="description"
          id="description"
          value={formData.description}
          onChange={onFormDataChange}
        ></PptTextArea>
      </div>
      <div className="flex-row-wrap w90">
        <PptInput
          labelText="Style"
          name="style"
          onChange={onFormDataChange}
          value={formData.style}
          placeholder="Style"
          className="w10rem"
        ></PptInput>
        <PptInput
          labelText="Subdivision"
          name="subdivision"
          onChange={onFormDataChange}
          value={formData.subdivision}
          placeholder="Subdivision"
          className="w10rem"
        ></PptInput>
      </div>

      <button
        className="headline-button"
        type="submit"
        id="listing-form-submit"
      >
        {actionText}
      </button>
    </form>
  );
};

export default ListingForm;
