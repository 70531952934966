import React, { useState, useMemo } from "react";
import PptItem from "../../components/PptItem";
import PptAction from "../../components/PptAction";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const friendlyPhone = number =>
  `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8, 12)}`;

const simpleDateString = date =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const CampaignCard = ({ userData, campaignID, onArchive, onLoad }) => {
  const [collapsed, setCollapsed] = useState(true);
  const campaign = useMemo(() => {
    if (!userData.campaigns) return null;
    return userData.campaigns.find(entry => entry._id === campaignID);
  }, [campaignID, userData.campaigns]);

  const listing = useMemo(() => {
    if (!campaign) return null;
    return userData.listings.find(
      listing => listing._id === campaign.listingID
    );
  }, [campaign, userData.listings]);

  const [smsLeadMagnet, voiceLeadMagnet] = useMemo(() => {
    if (!campaign) return [null, null];
    const sms = userData.twilioLMs.find(
      leadMagnet => leadMagnet._id === campaign.smsLeadMagnet._id
    );
    const voice = userData.twilioLMs.find(
      leadMagnet => leadMagnet._id === campaign.voiceLeadMagnet._id
    );
    return [sms, voice];
  }, [campaign, userData.twilioLMs]);

  const smsLeadCount = useMemo(() => {
    if (!smsLeadMagnet) return 0;
    return userData.leads.reduce(
      (count, lead) => (lead.source === smsLeadMagnet._id ? count + 1 : count),
      0
    );
  }, [smsLeadMagnet, userData.leads]);

  const voiceLeadCount = useMemo(() => {
    if (!voiceLeadMagnet) return 0;
    return userData.leads.reduce(
      (count, lead) =>
        lead.source === voiceLeadMagnet._id ? count + 1 : count,
      0
    );
  }, [voiceLeadMagnet, userData.leads]);

  const listingLeadCount = useMemo(() => {
    if (!listing) return 0;
    return userData.leads.reduce(
      (count, lead) => (lead.source === listing._id ? count + 1 : count),
      0
    );
  }, [listing, userData.leads]);

  const listingName = listing ? listing.uniqueName : "Listing Was Deleted";
  const createdOn = campaign ? new Date(parseInt(campaign.dateCreated)) : null;

  return (
    <div className={`${collapsed ? "collapse" : ""} flex-row-center w100`}>
      <div className="campaign-card flex-table flex-table-3col box-content padding-1rem w70percent z10">
        <button
          className="lead-magnet-action abs-top-right z10"
          onClick={() => setCollapsed(prev => !prev)}
        >
          {collapsed ? (
            <ExpandLessIcon></ExpandLessIcon>
          ) : (
            <ExpandMoreIcon></ExpandMoreIcon>
          )}
        </button>
        <div className="hide-on-collapse flex-table-item flex-left-column">
          <PptItem
            label="Name"
            value={campaign.name}
            labelClass="blue-text"
          ></PptItem>
          <PptItem
            label="Listing"
            value={listingName}
            labelClass="blue-text"
          ></PptItem>
          <PptItem
            label="Created"
            labelClass="blue-text"
            value={simpleDateString(createdOn)}
          ></PptItem>
        </div>
        <div className="hide-on-collapse flex-table-item flex-left-column">
          <PptItem
            label="SMS Lead Magnet"
            labelClass="blue-text"
            value={
              smsLeadMagnet
                ? `Text ${smsLeadMagnet.code} to ${friendlyPhone(
                    smsLeadMagnet.phoneNumber
                  )}`
                : "Lead Magnet Missing"
            }
            valueClass="text-small"
          ></PptItem>
          <PptItem
            label="Voice Lead Magnet"
            labelClass="blue-text"
            value={
              voiceLeadMagnet
                ? `Call ${friendlyPhone(
                    voiceLeadMagnet.phoneNumber
                  )} and enter ${voiceLeadMagnet.code}`
                : "Lead Magnet Missing"
            }
            valueClass="text-small"
          ></PptItem>
        </div>
        <div className="hide-on-collapse flex-table-item flex-table flex-table-2col">
          <div className="flex-table-item flex-row-center">
            <h2>{`${smsLeadCount +
              voiceLeadCount +
              listingLeadCount} Leads`}</h2>
          </div>
          <div className="flex-table-item flex-center-column">
            <span>{`${smsLeadCount} sms`}</span>
            <span>{`${voiceLeadCount} voice`}</span>
            <span>{`${listingLeadCount} listing`}</span>
          </div>
        </div>
        <div className="show-on-collapse flex-table flex-table-4col">
          <span className="flex-table-item">
            <PptItem
              label="Name"
              value={campaign.name}
              labelClass="blue-text"
            ></PptItem>
          </span>
          <span className="flex-table-item">
            <PptItem
              label="Listing"
              value={listingName}
              labelClass="blue-text"
            ></PptItem>
          </span>
          <span className="flex-table-item">
            <PptItem
              label="Created"
              labelClass="blue-text"
              value={simpleDateString(createdOn)}
            ></PptItem>
          </span>
          <span className="flex-table-item">
            <PptItem
              label="Leads"
              labelClass="blue-text"
              value={`${smsLeadCount +
                voiceLeadCount +
                listingLeadCount} Leads`}
            ></PptItem>
          </span>
        </div>
      </div>
      <div className="hide-on-collapse ppt-actions-left alt-bg dark-text">
        <PptAction
          className="blue-text"
          label="Load"
          icon={() => <UnarchiveIcon></UnarchiveIcon>}
          onClick={() => onLoad({ id: campaignID })}
        ></PptAction>
        <PptAction
          className="blue-text"
          label="Delete"
          icon={() => <DeleteIcon></DeleteIcon>}
          onClick={() => onArchive({ id: campaignID })}
        ></PptAction>
      </div>
    </div>
  );
};

export default CampaignCard;
