import React, { useState, useEffect } from "react";
import apiCreateListing from "../../../helpers/api/apiCreateListing";
import apiUpdateListing from "../../../helpers/api/apiUpdateListing";
import { getToken } from "../../../helpers/tokenManager";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { formDataDefaults } from "./formDataDefaults";
import {
  apiGetSignedS3Url,
  apiPutS3Images,
} from "../../../helpers/api/apiSignedS3Url";
import ActionsToggle from "../../../components/ActionsToggle";
import { getS3Url } from "../../../helpers/s3Cache";
import addressToUniqueName from "../../../helpers/addressToUniqueName";
import { importActionOptions } from "./listingActions";
import ListingForm from "./ListingForm";
import ListingPhotos from "./ListingPhotos";

const ListingEditor = ({
  userData,
  onUserDataChange,
  appState,
  onStateChange,
  editingListing,
}) => {
  // !! ##############################################################################
  // !! React State & Effects #######################################
  // !! ##############################################################################

  const [formData, setFormData] = useState(formDataDefaults);
  const [formImages, setFormImages] = useState([]);
  const [listingId, setListingId] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);

  const [selectedActionIndex, setSelectedActionIndex] = useState(0);
  const [showImporter, setShowImporter] = useState(false);

  useEffect(() => {
    if (!editingListing) return;
    setFormData({ ...editingListing });
    setListingId(editingListing._id);
    const getSignedUrls = async () => {
      if (!editingListing.images.length) return;
      const promises = editingListing.images.map(
        async (image) => await getS3Url({ key: image.keyName })
      );
      const urls = await Promise.all(promises);
      const imageDataArray = editingListing.images.map((imageData, index) => ({
        key: imageData.keyName,
        signedUrl: urls[index],
        selected: true,
      }));
      setFormImages(imageDataArray);
    };
    getSignedUrls();
  }, [editingListing]);

  // !! ##############################################################################
  // !! Handlers and Helper Methods
  // !! ##############################################################################

  const newSuccessMessage = (message) => {
    onStateChange({
      message: {
        message,
        id: "listing-editor-success",
        type: "success",
      },
    });
  };

  const clearForm = () => {
    setFormData(formDataDefaults);
    setFormImages([]);
    onStateChange({
      appKey: "listing",
      newState: {
        ...appState.listing,
        "editing-listing": null,
      },
    });
  };

  const handleNewError = (error) => {
    onStateChange({
      message: {
        type: "error",
        id: "listing-editor-error",
        message: error.message
          ? error.message
          : "an error has occurred with the last request",
      },
    });
  };

  const handleActionToggle = (index) => {
    setSelectedActionIndex(index);
  };

  const handleFormDataChange = async (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (key === "address") {
      debugger;
    }
  };

  const handleResult = async (result) => {
    const uniqueName = result.address
      ? addressToUniqueName(result.address)
      : "";

    // if (result.price) {
    //   result.price = result.price.replace(/\D/g, "");
    // }

    setFormData((prev) => ({
      ...prev,
      ...result,
      uniqueName,
    }));
    if (result.images && result.images.length) {
      const limitedImages = result.images.slice(0, 10);
      const imageS3Urls = await apiPutS3Images({
        images: limitedImages,
        userID: userData._id,
        token: getToken(),
      });

      setFormImages((prev) => [
        ...prev,
        ...imageS3Urls.map((item) => ({
          key: item.key,
          signedUrl: item.url,
          selected: true,
        })),
      ]);
    }
  };

  const handleImporterSubmit = (e) => {
    e.preventDefault();
    // submission is handled via autocomplete or a typing detector
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitStatus(true);
      const images = formImages.filter((image) => image.selected);
      const submitMethod = editingListing ? apiUpdateListing : apiCreateListing;
      const result = await submitMethod({
        ...formData,
        images,
        listingID: editingListing ? editingListing._id : null,
        token: getToken(),
        email: userData.email,
        userID: userData._id,
      });
      console.log(result);

      const imageObjects = images.map((image) => ({
        keyName: image.key,
        bucketName: "ppt",
      }));

      // *** some ES6 magic below : either we edit the listing in-place or add a new one
      const newListingData = editingListing
        ? userData.listings.map((listing) =>
            listing._id === formData._id
              ? {
                  ...formData,
                  images: imageObjects,
                  userID: userData._id,
                  _id: result.id,
                }
              : listing
          )
        : [
            ...userData.listings,
            {
              ...formData,
              images: imageObjects,
              userID: userData._id,
              _id: result.id,
            },
          ];

      onUserDataChange({
        key: "listings",
        value: newListingData,
      });

      newSuccessMessage(result.message);
      setSubmitStatus(false);
      setFormData(formDataDefaults);
      setFormImages([]);
      onStateChange({
        appKey: "listing",
        newState: {
          ...appState.listing,
          panel: "listing-view",
        },
      });
    } catch (error) {
      onStateChange({
        message: {
          type: "error",
          id: "listing-editor-error",
          message: error.message,
        },
      });
      setSubmitStatus(false);
    }
  };

  const handleNewUpload = async ({ key }) => {
    const signedUrl = await apiGetSignedS3Url({ key });
    setFormImages((prev) => [
      ...prev,
      {
        key,
        signedUrl,
        selected: true,
      },
    ]);
  };

  const toggleImageSelected = (key) => {
    setFormImages((prev) =>
      prev.map((imageData) => ({
        ...imageData,
        selected:
          imageData.key === key ? !imageData.selected : imageData.selected,
      }))
    );
  };

  // !! ##############################################################################
  // !! Rendering // Maps // Components
  // !! ##############################################################################

  const renderImporter = () => (
    <div className="panel-actions-right" id="listing-importer">
      {importActionOptions[selectedActionIndex].component({
        onDismiss: () => setShowImporter(false),
        onResult: handleResult,
        onError: handleNewError,
        onSubmit: handleImporterSubmit,
      })}
      <ActionsToggle
        actions={importActionOptions}
        selectedActionIndex={selectedActionIndex}
        onActionSelect={handleActionToggle}
      ></ActionsToggle>
    </div>
  );

  const renderActionButtons = () => (
    <div className="box-content padding-1rem flex-row-space-between w50percent">
      <button
        className="action-button"
        onClick={() =>
          onStateChange({
            appKey: "listing",
            newState: {
              ...appState.listing,
              panel: "listing-view",
            },
          })
        }
      >
        <ArrowBackIcon></ArrowBackIcon>
        back
      </button>
      <button className="action-button" onClick={() => setShowImporter(true)}>
        <DoubleArrowIcon></DoubleArrowIcon>
        import listing
      </button>
      <button className="action-button" onClick={clearForm}>
        <ClearIcon></ClearIcon>
        clear form
      </button>
    </div>
  );

  const imageUploadElements = formImages.map((imageData) => (
    <img
      key={imageData.key}
      className={`create-listing-image ${
        imageData.selected ? "create-listing-image-selected" : ""
      }`}
      src={imageData.signedUrl}
      alt="uploaded_image"
      onClick={(e) => {
        e.stopPropagation();
        toggleImageSelected(imageData.key);
      }}
    ></img>
  ));

  return (
    <>
      <section id="listing-editor">
        {showImporter ? renderImporter() : renderActionButtons()}
        <ListingPhotos
          userID={userData._id}
          keyPrefix="listings"
          onNewUpload={handleNewUpload}
        >
          {imageUploadElements}
        </ListingPhotos>
        <ListingForm
          formData={formData}
          onFormDataChange={handleFormDataChange}
          onSubmit={handleFormSubmit}
          listingId={listingId}
          actionText={editingListing ? "update" : "create"}
          submitStatus={submitStatus}
        ></ListingForm>
      </section>
    </>
  );
};

export default ListingEditor;
