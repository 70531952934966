/**
 * Post a file to a signed url and get back the s3 key
 * @param {file, signedUrl} params
 */
const s3PostFile = async ({ file, signedUrl }) => {
  try {
    const formData = new FormData();
    formData.append("Content-Type", file.type);
    formData.append("success_action_status", "201");
    Object.entries(signedUrl.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    const result = await fetch(signedUrl.url, {
      method: "POST",
      body: formData
    });
    const xmltext = await result.text();
    const parser = new DOMParser();
    const xmldoc = parser.parseFromString(xmltext, "text/xml");
    const key = xmldoc.querySelector("Key");
    console.log(key.textContent);
    return key.textContent;
  } catch (error) {
    throw error;
  }
};

export { s3PostFile };
