import React, { useCallback, useState, useEffect } from "react";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useDropzone } from "react-dropzone";
import { getS3Url } from "../helpers/s3Cache";

const DropUploadS3 = ({
  s3Item,
  onNewUpload,
  accept = "image/jpeg, image/png, image/webp",
  text = "Drop an Image Here",
  id,
  className = "box-content flex-center-column w12rem h12rem margin1rem-top circle border1rem dark-border-light-bg hover-border-blue",
  dragActiveClass = "hover-border-dashed",
  altText = "Uploaded Image"
}) => {
  const [signedUrl, setSignedUrl] = useState("");
  useEffect(() => {
    const getSignedUrl = async () => {
      if (!s3Item) return;
      const url = await getS3Url({ key: s3Item.keyName });
      setSignedUrl(url);
    };
    getSignedUrl();
  }, [s3Item]);
  const onDrop = useCallback(
    async acceptedFiles => {
      // eslint-disable-next-line
      if (onNewUpload) {
        acceptedFiles.forEach(onNewUpload);
      }
    },
    [onNewUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  const renderEmpty = () => (
    <>
      <AddPhotoAlternateIcon className="dark-text"></AddPhotoAlternateIcon>
      <h2 className="dark-text w10rem text-align-center">{text}</h2>
    </>
  );

  return (
    <div
      id="agent-profile-wrapper"
      className={`${className} ${isDragActive ? dragActiveClass : ""}`}
      {...getRootProps()}
    >
      <input {...getInputProps()}></input>
      {s3Item ? (
        <img className="ppt-cover-image" src={signedUrl} alt={altText}></img>
      ) : (
        renderEmpty()
      )}
    </div>
  );
};

export default DropUploadS3;
