import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiArchiveCampaign = async ({ token, id }) => {
  const mutation = `mutation {
        data: archiveCampaign(id:"${id}"){
          error
          message
          code
          id
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiArchiveCampaign;
