import React, { useState, useEffect } from "react";
import Contact from "./Contact";
import Settings from "./Settings";
import ChangePassword from "./ChangePassword";
import apiChangePassword from "../helpers/api/apiChangePassword";
import { getToken } from "../helpers/tokenManager";
import apiUpdateContact from "../helpers/api/apiUpdateContact";
import apiUpdateSettings from "../helpers/api/apiUpdateSettings";

import "./account.css";

const Account = ({ userData, onUserDataChange, onSave }) => {
  const [panel, setPanel] = useState("contact");
  const [formData, setFormData] = useState(null);
  const [changedPanels, setChangedPanels] = useState([]);
  const [message, setMessage] = useState("");
  const [passwordData, setPasswordData] = useState({
    prevPassword: "",
    newPassword: "",
    confirmPassword: "",
    passwordError: ""
  });

  useEffect(() => {
    if (!userData) return;
    const { email, website, contact, settings } = userData;
    // since contact is nullable we need to provide defaults
    const {
      address,
      address2,
      city,
      company,
      country,
      firstName,
      lastName,
      phone,
      state,
      zip
    } = contact
      ? contact
      : {
          address: "",
          address2: "",
          city: "",
          company: "",
          country: "",
          firstName: "",
          lastName: "",
          phone: "",
          state: "",
          zip: ""
        };
    setFormData({
      email,
      website,
      address,
      address2,
      city,
      company,
      country,
      firstName,
      lastName,
      phone,
      state,
      zip,
      settings
    });
  }, [userData]);

  const submitFunction = name =>
    ({
      contact: async () => {
        try {
          const result = await apiUpdateContact({
            token: getToken(),
            userID: userData._id,
            ...formData
          });
          if (result.error) throw new Error(result.message);

          onUserDataChange({
            key: "contact",
            value: formData
          });
          onUserDataChange({
            key: "website",
            value: formData.website
          });
        } catch (error) {
          setMessage(error.message);
        }
      },
      settings: async () => {
        try {
          const result = await apiUpdateSettings({
            token: getToken(),
            userID: userData._id,
            ...formData.settings
          });
          onUserDataChange({
            key: "settings",
            value: result
          });
        } catch (error) {
          setMessage(error.message);
        }
      },
      billing: () => {
        console.log("billing Submit");
      },
      password: async () => {
        if (passwordData.newPassword.length <= 7) {
          setPasswordData(prev => ({
            ...prev,
            passwordError: "Passwords must be at least 8 characters."
          }));
          return;
        } else if (passwordData.newPassword !== passwordData.confirmPassword) {
          setPasswordData(prev => ({
            ...prev,
            passwordError: "Passwords do not match."
          }));
          return;
        }
        const result = await apiChangePassword({
          userEmail: userData.email,
          prevPassword: passwordData.prevPassword,
          newPassword: passwordData.newPassword,
          token: getToken()
        });
        if (result.error) {
          setPasswordData(prev => ({
            ...prev,
            passwordError: result.message
          }));
          return;
        } else {
          setPasswordData(prev => ({
            ...prev,
            passwordError: "Your password was updated!"
          }));
        }
      }
    }[name]);

  const handleFormChange = ({ key, value }) => {
    setChangedPanels(prev => {
      if (prev.includes(panel)) return prev;
      return [...prev, panel];
    });
    setFormData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleFormSubmit = async () => {
    await submitFunction(panel)();
    if (onSave) onSave();
  };

  return (
    <div className="flex-center-start padding-1rem h100 borderbox">
      <nav className="flex-row-space-evenly w82percent">
        <button
          className={`action-button blue-text header-nav-item ${
            panel === "contact" ? "header-nav-active" : ""
          }`}
          onClick={() => setPanel("contact")}
        >
          contact
        </button>
        <button
          className={`action-button blue-text header-nav-item ${
            panel === "settings" ? "header-nav-active" : ""
          }`}
          onClick={() => setPanel("settings")}
        >
          settings
        </button>
        <button
          className={`action-button blue-text header-nav-item ${
            panel === "billing" ? "header-nav-active" : ""
          }`}
          onClick={() => setPanel("billing")}
        >
          billing
        </button>
        <button
          className={`action-button blue-text header-nav-item ${
            panel === "password" ? "header-nav-active" : ""
          }`}
          onClick={() => setPanel("password")}
        >
          password
        </button>
      </nav>
      {formData && panel === "contact" ? (
        <Contact onFormChange={handleFormChange} formData={formData}></Contact>
      ) : null}
      {formData && panel === "settings" ? (
        <Settings
          formData={formData}
          onFormChange={handleFormChange}
        ></Settings>
      ) : null}
      {formData && panel === "password" ? (
        <ChangePassword
          passwordData={passwordData}
          onPasswordChange={({ key, value }) => {
            setChangedPanels(prev =>
              prev.includes("password") ? prev : [...prev, "password"]
            );
            setPasswordData(prev => ({
              ...prev,
              [key]: value
            }));
          }}
        ></ChangePassword>
      ) : null}
      {formData && panel === "billing" ? (
        <span className="flex-center-column flex1">
          Billing will be handled in Pipeline Pro Tools 1.0 until this software
          is out of Beta.
        </span>
      ) : null}
      <button
        id="account-save-button"
        className="headline-button abs-lower-right-1rem green-text"
        disabled={changedPanels.length === 0}
        onClick={handleFormSubmit}
      >
        save
      </button>
      {message ? <h3 id="special-message">{message}</h3> : null}
    </div>
  );
};

export default Account;
