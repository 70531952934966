import React, { useState } from "react";
import PptInput from "../../components/PptInput";

const SetNewGoal = ({ onSubmit, suggestedGoal = "65-100" }) => {
  const [goal, setGoal] = useState(null);

  return (
    <div className="flex-center-even">
      <h3 className="blue-text">What's your monthly lead goal?</h3>
      <a
        className="text-small"
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.google.com/spreadsheets/d/1XnuUDACgCdE0uZFSx0bl-aWdNPQSKaSkPcbgNoYF9EE/edit#gid=0"
      >
        Use our calculator...
      </a>
      <PptInput
        labelText="Leads / Month"
        value={goal}
        placeholder={suggestedGoal}
        onChange={e => setGoal(e.target.value)}
        className="w10rem blue-text"
        type="number"
      ></PptInput>
      <button
        className="action-button abs-lower-right-1rem green-text"
        onClick={() => onSubmit(goal)}
      >
        Set
      </button>
    </div>
  );
};

export default SetNewGoal;
