import React from "react";
import PptInput from "../components/PptInput";

const ChangePassword = ({ passwordData, onPasswordChange }) => {
  return (
    <form className="flex-center-column flex1">
      {passwordData.passwordError ? (
        <span className="red-text">{passwordData.passwordError}</span>
      ) : (
        <span>Change Password</span>
      )}
      <PptInput
        labelText="Old Password"
        type="password"
        placeholder="your current password"
        className="w20rem"
        autoComplete={"current-password"}
        value={passwordData ? passwordData.prevPassword : ""}
        onChange={e =>
          onPasswordChange({ key: "prevPassword", value: e.target.value })
        }
      ></PptInput>
      <PptInput
        labelText="New Password"
        type="password"
        placeholder="new password"
        className="w20rem"
        autoComplete={"new-password"}
        value={passwordData ? passwordData.newPassword : ""}
        onChange={e =>
          onPasswordChange({ key: "newPassword", value: e.target.value })
        }
      ></PptInput>
      <PptInput
        labelText="Confirm New Password"
        type="password"
        placeholder="new password"
        className="w20rem"
        autoComplete={"new-password"}
        value={passwordData ? passwordData.confirmPassword : ""}
        onChange={e =>
          onPasswordChange({ key: "confirmPassword", value: e.target.value })
        }
      ></PptInput>
    </form>
  );
};

export default ChangePassword;
