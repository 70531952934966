import React from "react";
import LeadMagnetEditor from "./LeadMagnetEditor/LeadMagnetEditor";
import LeadMagnetView from "./LeadMagnetView/LeadMagnetView";

import "./leadMagnets.css";

const LeadMagnets = ({
  appState,
  onStateChange,
  userData,
  onUserDataChange,
  lmType = "sms"
}) => {
  return (
    <section id="lead-magnet-section">
      <LeadMagnetEditor
        userData={userData}
        onUserDataChange={onUserDataChange}
        lmType={lmType}
        appState={appState}
        onStateChange={onStateChange}
      ></LeadMagnetEditor>
      <LeadMagnetView
        userData={userData}
        onUserDataChange={onUserDataChange}
        lmType={lmType}
        appState={appState}
        onStateChange={onStateChange}
      ></LeadMagnetView>
    </section>
  );
};

export default LeadMagnets;
