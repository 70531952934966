import React from "react";

const PptTextArea = ({
  labelText = "",
  name = "",
  onChange = null,
  value = "",
  id = "",
  className = "",
  rows = 5
}) => {
  return (
    <label
      className={`ppt-input-container w90 ${className}`}
      htmlFor={name}
      id={id}
    >
      <span className="ppt-input-label">{labelText}</span>
      <textarea
        name={name}
        value={value ? value : ""}
        onChange={onChange}
        className="ppt-text-area w90 transform-up-half-rem"
        rows={rows}
      ></textarea>
    </label>
  );
};

export default PptTextArea;
