import React from "react";

const Goal = ({ leadNumber = 62, goalNumber = 100 }) => {
  const withinPercentage =
    leadNumber < goalNumber ? (goalNumber - leadNumber) / goalNumber : 0;
  const textClass =
    withinPercentage < 0.1
      ? "green-text"
      : withinPercentage < 0.25
      ? "orange-text"
      : "red-text";

  return (
    <div className="flex-center-column">
      <h2 className={`four-rem-text ${textClass}`}>{leadNumber}</h2>
      <h3 className="two-rem-text">leads</h3>
      <h4 className={textClass}>{`goal this month: ${goalNumber}`}</h4>
    </div>
  );
};

export default Goal;
