const tokenKey = "ppt-token";

const setToken = token => {
  localStorage.setItem(tokenKey, token);
};

const getToken = () => {
  return localStorage.getItem(tokenKey);
};

const processResponse = ({
  error,
  code,
  id,
  message,
  payload,
  refreshToken
}) => {
  console.log(message);
  console.log(error);
  if (error) return false;
  if (refreshToken) setToken(payload);
  return true;
};

export { getToken, processResponse };
