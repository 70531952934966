import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopySnippet = ({ displayText, snippet, onCopy, className }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    if (onCopy) onCopy();
  };

  const handleStateReset = () => {
    setCopied(false);
  };

  return (
    <div
      className={`copy-snippet-wrapper tooltip-wrapper box-shadow-subtle ${
        copied ? "copy-snipped-copied" : ""
      } ${className ? className : ""}`}
      onMouseLeave={handleStateReset}
    >
      <CopyToClipboard text={snippet} onCopy={handleCopy}>
        <span className="copy-snippet-content">{displayText}</span>
      </CopyToClipboard>
      <span className="tooltip tooltip-right">
        {copied ? `Copied!` : `Click to copy text.`}
      </span>
    </div>
  );
};
export default CopySnippet;
