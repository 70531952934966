import Cookies from "js-cookie";
import { processResponse } from "./tokenManager";
/**
 * checks credentials, which should include an email and password
 * eventually this will check JWT status
 * @param {credentials} object
 */
const checkAuth = async ({ email, password }) => {
  const query = `{
    checkCredentials(credentials:{
      email:"${email}",
      password:"${password}"
    }){
      error
      message
      id
      refreshToken
      code
      payload
    }
  }`;
  try {
    const result = await fetch("/api", {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    if (!json || !json.data) return false;
    return processResponse(json.data.checkCredentials);
  } catch (error) {
    return false;
  }
};
/**
 * If 'cookie' is truthy will set status to logged in (browser side only)
 * @param {bool} cookie
 */
const setAuthCookie = (cookie = false) => {
  if (!cookie) {
    Cookies.set("ppt-auth", "0");
    return;
  }
  Cookies.set("ppt-auth", "1", { expires: 1 });
};
/**
 * Get the cookie-level status of the login state, used for routing between login.
 */
const isLoggedIn = () => {
  return Cookies.get("ppt-auth") === "1";
};

export { checkAuth, setAuthCookie, isLoggedIn };
