import React from "react";
import PptInput from "../components/PptInput";

const Contact = ({
  formData,
  onFormChange,
  onFormSubmit,
  messageText = ""
}) => (
  <form className="flex-row-center padding-1rem flex1">
    <PptInput
      placeholder="first name"
      value={formData.firstName}
      labelText="First Name"
      onChange={e => onFormChange({ key: "firstName", value: e.target.value })}
      className="w12rem"
    ></PptInput>
    <PptInput
      placeholder="last name"
      value={formData.lastName}
      labelText="Last Name"
      onChange={e => onFormChange({ key: "lastName", value: e.target.value })}
      className="w12rem"
    ></PptInput>
    <PptInput
      placeholder="user@host"
      value={formData.email}
      labelText="Email"
      onChange={e => onFormChange({ key: "email", value: e.target.value })}
      className="w16rem"
    ></PptInput>
    <PptInput
      placeholder="www.business.com"
      value={formData.website}
      labelText="Website"
      onChange={e => onFormChange({ key: "website", value: e.target.value })}
      className="w16rem"
    ></PptInput>
    <PptInput
      placeholder="123 456 7890"
      value={formData.phone}
      labelText="Phone Number"
      onBlur={e => {
        const value = e.target.value;
        const digits = value.replace(/\D/g, "");
        if (digits.length === 10) {
          onFormChange({ key: "phone", value: `+1${digits}` });
        }
      }}
      onChange={e => onFormChange({ key: "phone", value: e.target.value })}
      className="w12rem"
    ></PptInput>
    <PptInput
      placeholder="Company, Inc."
      value={formData.company}
      labelText="Company"
      onChange={e => onFormChange({ key: "company", value: e.target.value })}
      className="w12rem"
    ></PptInput>
    <PptInput
      placeholder="123 Something Street"
      value={formData.address}
      labelText="Address"
      onChange={e => onFormChange({ key: "address", value: e.target.value })}
      className="w16rem"
    ></PptInput>
    <PptInput
      placeholder="Unit 987"
      value={formData.address2}
      labelText="Address 2"
      onChange={e => onFormChange({ key: "address2", value: e.target.value })}
      className="w12rem"
    ></PptInput>
    <PptInput
      placeholder="Springfield"
      value={formData.city}
      labelText="City"
      onChange={e => onFormChange({ key: "city", value: e.target.value })}
      className="w10rem"
    ></PptInput>
    <PptInput
      placeholder="ST"
      value={formData.state}
      labelText="State"
      onChange={e => onFormChange({ key: "state", value: e.target.value })}
      className="w5rem"
    ></PptInput>
    <PptInput
      placeholder="12345"
      value={formData.zip}
      labelText="Zip"
      onChange={e => onFormChange({ key: "zip", value: e.target.value })}
      className="w5rem"
    ></PptInput>
  </form>
);

export default Contact;
