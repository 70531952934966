import React, { useState, useEffect } from "react";
import ListingSelection from "./ListingSelection";
import CopySnippet from "../../../components/CopySnippet";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const friendlyPhone = number =>
  `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8, 12)}`;
const friendlyDate = rawDate => {
  const date = new Date(parseInt(rawDate));
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const smsLink = ({ phoneNumber, code }) =>
  `sms:${phoneNumber}&body=${encodeURI(code)}`;

const telLink = ({ phoneNumber, code }) => `tel:${phoneNumber},${code}#`;

const LeadMagnetCard = ({
  _id: id,
  code,
  phoneNumber,
  listingName,
  dateCreated,
  leads,
  responseText,
  customizable = false,
  onListingChange,
  onResponseTextChange,
  onConfirmedDelete,
  listings,
  lmType
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [editedResponseText, setEditedRepsonseText] = useState("");
  const [responseTextTimeout, setResponseTextTimeout] = useState(null);
  const [showDeletePanel, setShowDeletePanel] = useState(false);

  const handleResponseTextInput = e => {
    const value = e.target.value;
    clearTimeout(responseTextTimeout);
    setEditedRepsonseText(value);
    setResponseTextTimeout(
      setTimeout(() => {
        // do update
        onResponseTextChange({ id, responseText: value });
      }, 1000)
    );
  };

  useEffect(() => {
    setEditedRepsonseText(prev => (prev ? prev : responseText));
  }, [responseText]);

  const handleDeleteToggle = () => {
    setShowDeletePanel(prev => !prev);
  };

  const handleConfirmDelete = () => {
    onConfirmedDelete({ id });
  };

  // !! ####################################################################
  // !! RENDER OPTIONS
  // !! ####################################################################

  return (
    <>
      <div
        className={`${
          collapsed ? "lead-magnet-card collapse" : "lead-magnet-card"
        } box-content padding-1rem w90percent margin1rem-top flex-row-space-evenly`}
      >
        <div className="hide-on-collapse lead-magnet-card-info flex-left-column">
          <ListingSelection
            listings={listings}
            selection={listingName}
            onChange={selection =>
              onListingChange({ id, uniqueName: selection })
            }
          ></ListingSelection>
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Phone Number:</span>
            <span className="lead-magnet-value">
              {friendlyPhone(phoneNumber)}
            </span>
          </div>
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Code:</span>
            <span className="lead-magnet-value">{code}</span>
          </div>
        </div>
        <div className="hide-on-collapse ppt-input-container margin-reset">
          <span className="ppt-input-label">Response Text</span>
          <textarea
            className="ppt-text-area transform-up-half-rem"
            value={editedResponseText}
            onChange={handleResponseTextInput}
            rows="4"
          ></textarea>
        </div>
        <div className="hide-on-collapse flex-left-column">
          {lmType === "sms" ? (
            // !! SMS INFO
            <>
              <CopySnippet
                displayText={`Text ${code} to ${friendlyPhone(phoneNumber)}`}
                snippet={`Text ${code} to ${friendlyPhone(phoneNumber)}`}
              ></CopySnippet>
              <CopySnippet
                displayText={`Copy SMS Link`}
                snippet={`<a href="${smsLink({
                  phoneNumber,
                  code
                })}">Click To Text</a>`}
                className="margin1rem-top"
              ></CopySnippet>
              <span className="w100percent flex-row-center text-small">
                Example:{" "}
                <a href={smsLink({ phoneNumber, code })}> Click To Text</a>
              </span>
            </>
          ) : (
            // !! VOICE INFO
            <>
              <CopySnippet
                displayText={`Call ${friendlyPhone(
                  phoneNumber
                )} and enter ${code}`}
                snippet={`Call ${friendlyPhone(phoneNumber)} and enter ${code}`}
              ></CopySnippet>
              <CopySnippet
                displayText={`Copy One-Click Link`}
                snippet={`<a href="${telLink({
                  phoneNumber,
                  code
                })}">Click To Call</a>`}
                className="margin1rem-top"
              ></CopySnippet>
              <span className="w100percent flex-row-center text-small">
                Example:{" "}
                <a href={telLink({ phoneNumber, code })}> Click To Call</a>
              </span>
            </>
          )}
        </div>
        <div className="show-on-collapse flex-row-space-evenly">
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Phone Number:</span>
            <span className="lead-magnet-value">
              {friendlyPhone(phoneNumber)}
            </span>
          </div>
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Code:</span>
            <span className="lead-magnet-value">{code}</span>
          </div>
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Date Created:</span>
            <span className="lead-magnet-value">
              {friendlyDate(dateCreated)}
            </span>
          </div>
          <div className="lead-magnet-item">
            <span className="lead-magnet-label">Leads:</span>
            <span className="lead-magnet-value">
              {leads && leads.length ? leads.length : "0"}
            </span>
          </div>
        </div>
        <button
          className="lead-magnet-action abs-lower-right hide-on-collapse"
          onClick={handleDeleteToggle}
        >
          <DeleteIcon></DeleteIcon>
        </button>
        <button
          className="lead-magnet-action abs-top-right"
          onClick={() => setCollapsed(prev => !prev)}
        >
          {collapsed ? (
            <ExpandLessIcon></ExpandLessIcon>
          ) : (
            <ExpandMoreIcon></ExpandMoreIcon>
          )}
        </button>
        <div
          className={
            showDeletePanel
              ? "slide-under-right show-panel"
              : "slide-under-right hide-panel"
          }
        >
          <button className="small-button" onClick={handleConfirmDelete}>
            delete
          </button>
        </div>
        <span className="abs-top-left text-small blue-text flex-row-center">
          {lmType === "sms" ? <SmsIcon></SmsIcon> : null}
          {lmType === "voice" ? <PhoneIcon></PhoneIcon> : null}
          {lmType}
        </span>
      </div>
    </>
  );
};

export default LeadMagnetCard;
