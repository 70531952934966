import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiUpdateSettings = async ({
  notifyEmail,
  notifySms,
  theme,
  licensedRealtor,
  monthlyLeadGoal,
  userID,
  token
}) => {
  const mutation = `mutation {
        data: updateUserSettings(userID:"${userID}", settings:{
          notifyEmail:${notifyEmail}
          notifySms:${notifySms}
          theme:"${theme}"
          licensedRealtor:${licensedRealtor}
          monthlyLeadGoal:${monthlyLeadGoal}
        }){
          notifyEmail
          notifySms
          theme
          monthlyLeadGoal
          licensedRealtor
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateSettings;
