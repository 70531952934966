import React, { useState, useEffect } from "react";
import { getS3Url } from "../../helpers/s3Cache";

const PhotoDownload = ({
  s3urls,
  filenameRoot = "file",
  onError,
  clickedClassName,
  className,
  linkClass,
  linkIcon,
  downloadedIcon
}) => {
  const [urlData, setUrlData] = useState([]);
  useEffect(() => {
    if (!s3urls || !s3urls.length) setUrlData([]);
    const populateUrls = async () => {
      try {
        const promises = s3urls.map(
          async s3url =>
            await getS3Url({
              key: s3url.keyName
            })
        );
        const result = await Promise.all(promises);
        setUrlData(
          result.map((url, index) => ({
            url,
            clicked: false,
            filename: `${filenameRoot}_${index}.jpg`
          }))
        );
      } catch (error) {
        onError(error);
      }
    };
    populateUrls();
  }, [s3urls, filenameRoot, onError]);

  const handleLinkClick = index => {
    setUrlData(prev =>
      prev.map((data, i) => (index === i ? { ...data, clicked: true } : data))
    );
  };

  const downloadLinks = urlData.map((data, index) => (
    <a
      className={data.clicked ? clickedClassName : className}
      key={index}
      href={data.url}
      download={data.filename}
      onClick={() => handleLinkClick(index)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {data.filename}
      {data.clicked ? downloadedIcon() : linkIcon()}
    </a>
  ));
  return <>{downloadLinks}</>;
};

export default PhotoDownload;
