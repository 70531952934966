import React, { useState, useEffect } from "react";
import TwilioConnectButton from "../../../components/TwilioConnectButton";
import { getToken } from "../../../helpers/tokenManager";

const TwilioAccountConnect = ({ onConnect, userIsConnected }) => {
  const [childWindow, setChildWindow] = useState(null);

  useEffect(() => {
    if (!childWindow) return;
    const interval = setInterval(() => {
      if (childWindow.closed) {
        clearInterval(interval);
        setChildWindow(null);
        console.log("*** Window was closed!");
        // todo add side effect of window closing
        if (onConnect) onConnect();
      }
    }, 500);
    return () => clearInterval(interval);
  }, [childWindow, onConnect]);

  const handleChildWindowOpen = window => setChildWindow(window);

  return (
    <div id="twilio-account-connect" className="flex-center-column">
      <span className="margin1rem w42rem">
        {userIsConnected
          ? `Your account is already connected to Twilio. If you need to connect a new account, 
          clicking the button below will open a new window.`
          : `Customized phone numbers and codes require a linked Twilio Account.
          Click the connect button to either create a new account or link an
          existing one. Note: Only phone numbers purchased in-app by Pipeline Pro
          Tools can be used.`}
      </span>
      <TwilioConnectButton
        token={getToken()}
        onChildWindowOpen={handleChildWindowOpen}
      ></TwilioConnectButton>
    </div>
  );
};

export default TwilioAccountConnect;
