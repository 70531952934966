import React from "react";

import "./dots.css";

const LoadingWrapper = ({ children, loading, className = "", id = "" }) => {
  return loading && loading !== "false" ? (
    <div
      className={className ? `dot-spinner ${className}` : "dot-spinner"}
      id={id}
    >
      <div className="dot1"></div>
      <div className="dot2"></div>
    </div>
  ) : children ? (
    children
  ) : null;
};

export default LoadingWrapper;
