import apiAddress from "./apiAddress";

const apiCreateTwilioLM = async ({
  token,
  phoneNumber,
  code,
  userID,
  listingID,
  responseText,
  notify: { email, sms },
  lmType
}) => {
  const mutation = `mutation {
        createTwilioLeadMagnet(data:{
          phoneNumber:"${phoneNumber}"
          code:"${code}"
          userID:"${userID}"
          ${listingID ? "listingID:" + listingID : ""}
          responseText:"${responseText}"
          notify: {
            email:"${email}"
            sms:"${sms}"
          }
          lmType:"${lmType}"
        }){
          _id
          phoneNumber
          code
          userID
          leads
          listingID
          dateCreated
          responseText
          notify {
            email
            sms
          }
          uniqueHits
          lmType
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.createTwilioLeadMagnet;
  } catch (error) {
    throw error;
  }
};

export default apiCreateTwilioLM;
