import apiAddress from "./apiAddress";

const apiArchiveListing = async ({ listingID, token }) => {
  const mutation = `mutation {
        archiveListing (listingID: "${listingID}") {
          error
          message
          code
          refreshToken
          id
          payload
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);
    if (json.data && json.data.archiveListing.error) {
      throw new Error(json.data.archiveListing.message);
    }
    return json.data.archiveListing;
  } catch (error) {
    throw error;
  }
};

export default apiArchiveListing;
