// import apiAddress from "./apiAddress";

const apiZillowListing = async ({ url, token }) => {
  // const query = `{
  //       zillowListing(url:"${url}"){
  //         address
  //         address2
  //         city
  //         state
  //         zip
  //         beds
  //         fullBaths
  //         halfBaths
  //         price
  //         yearBuilt
  //         images
  //       }
  //     }`;

  try {
    // const result = await fetch(apiAddress(), {
    //   method: "POST",
    //   withCredentials: true,
    //   credentials: "include",
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   },
    //   body: JSON.stringify({ query })
    // });
    const result = await fetch(`/api/zillowizer?url=${url}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer: ${token}`
      }
    });
    const json = await result.json();
    return json;
  } catch (error) {
    throw error;
  }
};

export default apiZillowListing;
