import apiAddress from "./apiAddress";

const apiUpdateAgentPic = async ({ key, token }) => {
  const mutation = `mutation {
        updateUser(userUpdate:{
          agentProfilePic: {
            bucketName:"ppt"
            keyName:"${key}"
          }
        }){
          error
          message
          code
          id
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);
    if (json.data && json.data.updateUser.error) {
      throw new Error(json.data.updateUser.message);
    }
    return json.data.updateUser;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateAgentPic;
