import React, { useState } from "react";
import Logo from "../Header/Logo";
import { checkAuth, setAuthCookie } from "../helpers/authHelper";
import LoadingWrapper from "../components/LoadingWrapper";

const Login = props => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    error: false,
    loggedIn: props.loggedIn ? props.loggedIn : false
  });
  const [submittedLogin, setSubmittedLogin] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSignUpClick = e => {
    const { history } = props;
    history.push("/signup");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmittedLogin(true);
    const valid = await checkAuth(credentials);
    setSubmittedLogin(false);

    setCredentials(prev => ({
      ...prev,
      error: !valid,
      loggedIn: valid
    }));
    setAuthCookie(valid);
    if (valid && props.prefersReducedMotion) return;
    // todo handle prefersReducedMotion
  };

  const handleAnimationEnd = () => {
    const { history } = props;
    history.push("/");
  };

  const loginMessage = () => {
    if (credentials.error)
      return <div id="login-message">Incorrect username or email.</div>;
    return null;
  };

  return (
    <form
      id="root-login"
      onSubmit={handleSubmit}
      className={credentials.loggedIn ? "exit-form" : ""}
    >
      <section
        id="login-credentials"
        className="box-content"
        onAnimationEnd={handleAnimationEnd}
      >
        <Logo></Logo>
        <div id="login-input">
          <input
            className="ppt-input"
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Email"
            value={credentials.email}
            onChange={e => handleChange(e)}
            required
          ></input>
          <input
            className="ppt-input"
            type="password"
            name="password"
            autoComplete="current-password"
            placeholder="Password"
            value={credentials.password}
            onChange={handleChange}
            required
          ></input>
        </div>
        {loginMessage()}
      </section>
      <section id="login-actions" className="box-content">
        <button
          type="button"
          className="headline-button headline-button-secondary"
          onClick={handleSignUpClick}
        >
          sign up
        </button>
        <LoadingWrapper loading={submittedLogin}></LoadingWrapper>
        <button type="submit" className="headline-button">
          login
        </button>
      </section>
    </form>
  );
};

export default Login;
