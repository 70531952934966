import apiAddress from "./apiAddress";

const apiCreateWebLM = async ({
  token,
  uniqueName,
  userID,
  callToAction,
  titleStory,
  theme
}) => {
  const mutation = `mutation {
        createWebLeadMagnet(data:{
          uniqueName:"${uniqueName}"
          userID:"${userID}"
          callToAction:"${encodeURIComponent(callToAction)}"
          titleStory:"${encodeURIComponent(titleStory)}"
          theme:"${theme}"
        }){
          error
          message
          code
          id
        }
      }`;

  try {
    console.log(mutation);
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.createWebLeadMagnet;
  } catch (error) {
    throw error;
  }
};

export default apiCreateWebLM;
