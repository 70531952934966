import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import SinglePageFramework from "./SPA/SinglePageFramework";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login}></Route>
        <Route path="/signup" component={SignUp}></Route>
        <Route path="/" component={SinglePageFramework}></Route>
      </Switch>
    </Router>
  );
}

export default App;
