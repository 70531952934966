import React from "react";
import Logo from "./Logo";

const SidebarNav = ({ children }) => (
  <nav className="box-content" id="sidebar-nav">
    <Logo></Logo>
    {children}
  </nav>
);

export default SidebarNav;
