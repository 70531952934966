import React, { useState, useEffect } from "react";
import CopySnippet from "../../components/CopySnippet";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";

const friendlyPhone = number =>
  `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8, 12)}`;

const smsLink = ({ phoneNumber, code }) =>
  `sms:${phoneNumber}&body=${encodeURI(code)}`;

const SimpleLeadMagnetCard = ({
  _id: id,
  code,
  phoneNumber,
  listingName,
  responseText,
  onResponseTextChange,
  lmType
}) => {
  const [editedResponseText, setEditedRepsonseText] = useState("");
  const [responseTextTimeout, setResponseTextTimeout] = useState(null);

  const handleResponseTextInput = e => {
    const value = e.target.value;
    clearTimeout(responseTextTimeout);
    setEditedRepsonseText(value);
    setResponseTextTimeout(
      setTimeout(() => {
        // do update
        onResponseTextChange({ id, responseText: value });
      }, 1000)
    );
  };

  useEffect(() => {
    setEditedRepsonseText(prev => (prev ? prev : responseText));
  }, [responseText]);

  return (
    <div className="lead-magnet-card box-content padding-1rem w90percent margin1rem-top flex-row-space-evenly">
      <div className="lead-magnet-card-info flex-left-column">
        <h3>Listing: {listingName}</h3>
        <div className="lead-magnet-item">
          <span className="lead-magnet-label">Phone Number:</span>
          <span className="lead-magnet-value">
            {friendlyPhone(phoneNumber)}
          </span>
        </div>
        <div className="lead-magnet-item">
          <span className="lead-magnet-label">Code:</span>
          <span className="lead-magnet-value">{code}</span>
        </div>
      </div>
      <div className="ppt-input-container margin-reset">
        <span className="ppt-input-label">Response Text</span>
        <textarea
          className="ppt-text-area transform-up-half-rem"
          value={editedResponseText}
          onChange={handleResponseTextInput}
          rows="4"
        ></textarea>
      </div>
      <div className="flex-left-column">
        <CopySnippet
          displayText={`Text ${code} to ${friendlyPhone(phoneNumber)}`}
          snippet={`Text ${code} to ${friendlyPhone(phoneNumber)}`}
        ></CopySnippet>
        <CopySnippet
          displayText={`Copy HTML SMS Link`}
          snippet={`<a href="${smsLink({
            phoneNumber,
            code
          })}">Click To Text</a>`}
          className="margin1rem-top"
        ></CopySnippet>
        <span className="w100percent flex-row-center text-small">
          Example: <a href={smsLink({ phoneNumber, code })}> Click To Text</a>
        </span>
      </div>
      <span className="abs-top-left text-small blue-text flex-row-center">
        {lmType === "sms" ? <SmsIcon></SmsIcon> : null}
        {lmType === "voice" ? <PhoneIcon></PhoneIcon> : null}
        {lmType}
      </span>
    </div>
  );
};

export default SimpleLeadMagnetCard;
