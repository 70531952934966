const websiteCopy = {
  callToAction: "Search Beyond Zillow",
  callToAction2: "Compare ALL the top home searches here.",
  title: "Buying or Selling a House Shouldn't Be This Hard",
  titleStory: ({
    firstName,
    lastName
  }) => `What if there was a proven formula for buying or selling a house? A way to guarantee that you find the right home and don’t pay too much? 
Want to see every home that matches your criteria, not just the ones on Zillow?
No problem.
Want to see the listing the minute it’s listed, not days or weeks later? Easy.
Want to know if that home you just fell in love with is overpriced? Of course.
This is what buying a home should look like. But does it? Not usually. 
My name is ${firstName +
    " " +
    lastName}, and I want to make finding the right home easy, as it should be. 
That’s why I give you complete access to search the MLS, along with all the other home searches you probably don’t know about. It’s 100% free, no strings attached.
Start by clicking the blue button below.`,
  theme: "pipeline",
  hostAddress: "onehomesearch.site"
};

const defaultKeywords = [
  "walkout",
  "shed",
  "covered",
  "porch",
  "pool",
  "inground",
  "patio",
  "hot",
  "tub",
  "lawn",
  "sprinkler",
  "fencing",
  "privacy",
  "deck",
  "barn",
  "balcony",
  "attic",
  "basement",
  "double",
  "vanities",
  "garden",
  "tub",
  "bonus",
  "room",
  "granite",
  "cermaic",
  "tile",
  "handicapped",
  "accessible",
  "in-law",
  "suite",
  "kitchen",
  "island",
  "downstairs",
  "master",
  "sky",
  "lights",
  "walk-in",
  "closet",
  "condominium",
  "townhouse",
  "rowhouse",
  "ranch",
  "two",
  "story",
  "2",
  "three",
  "3",
  "split-level",
  "tri-level",
  "cape",
  "cod",
  "colonial",
  "bungalow",
  "contemporary",
  "modern",
  "spanish",
  "style",
  "brick",
  "stucco"
];

export { websiteCopy, defaultKeywords };
