import React from "react";
import ListingCardImage from "./ListingCardImage";
import ArchiveIcon from "@material-ui/icons/Archive";
import EditIcon from "@material-ui/icons/Edit";
import PanoramaIcon from "@material-ui/icons/Panorama";

const ListingCard = ({
  listing,
  onListingArchive,
  onListingEdit,
  onListingView,
  subdomain = "www"
}) => {
  return (
    <div className="listing-card-container">
      <div className="listing-card">
        <ListingCardImage images={listing.images} index={0}></ListingCardImage>
        <div className="listing-card-info">
          <h4 className="listing-card-title">{listing.uniqueName}</h4>
          <span className="listing-card-address">{listing.address}</span>
          <span className="listing-card-price">${listing.price}</span>
          <span className="listing-card-leads">0 Leads</span>
        </div>
      </div>
      <div className="listing-card-actions">
        <button
          className="listing-card-action"
          onClick={() => onListingArchive({ listingID: listing._id })}
        >
          <ArchiveIcon></ArchiveIcon>
          <span className="listing-card-action-label">archive</span>
        </button>
        <button className="listing-card-action" onClick={onListingEdit}>
          <EditIcon></EditIcon>
          <span className="listing-card-action-label">edit</span>
        </button>
        <button
          className="listing-card-action"
          onClick={() =>
            window.open(
              `https://${subdomain}.onehomesearch.site/${listing.uniqueName}`,
              "_blank"
            )
          }
        >
          <PanoramaIcon></PanoramaIcon>
          <span className="listing-card-action-label">view</span>
        </button>
      </div>
    </div>
  );
};

export default ListingCard;
