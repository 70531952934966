import apiAddress from "./apiAddress";

const apiArchiveTwilioLM = async ({ id, token }) => {
  const mutation = `mutation {
        archiveTwilioLeadMagnet(id: "${id}"){
        error
        message
        code
        id
        payload
      }
    }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.archiveTwilioLeadMagnet;
  } catch (error) {
    throw error;
  }
};

export default apiArchiveTwilioLM;
