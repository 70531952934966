import React from "react";

const MessageCard = ({ type, time, id, message, onClick }) => {
  // todo add timer stuff
  return (
    <div
      className="glass padding-1rem margin1rem-top w100 flex-center-column position-rel message-card"
      id={id}
      onClick={onClick}
    >
      <div
        className={`z10 box-shadow-subtle message-card-message message-${type}`}
      >
        <span>{message}</span>
      </div>
      <span id="message-timer"></span>
    </div>
  );
};

export default MessageCard;
