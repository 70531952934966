import React, { useMemo, useState } from "react";
import Goal from "./Goal";
import SetNewGoal from "./SetNewGoal";
import SetNotifyPrefs from "./SetNotifyPrefs";
import LeadsOverTime from "./LeadsOverTime";
import PptTextArea from "../../components/PptTextArea";
import apiSubmitFeedback from "../../helpers/api/apiSubmitFeedback";
import apiUpdateSettings from "../../helpers/api/apiUpdateSettings";
import leadsBetweenDates from "../../helpers/leadsBetweenDates";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getToken } from "../../helpers/tokenManager";

import "./dashboard.css";

const leadSourceCount = ({ id, userData }) => {
  return userData.leads.reduce(
    (count, lead) => (lead.source === id ? count + 1 : count),
    0
  );
};

const campaignCount = ({ campaign, userData }) =>
  leadSourceCount({ id: campaign.listingID, userData }) +
  leadSourceCount({ userData, id: campaign.smsLeadMagnet._id }) +
  leadSourceCount({ userData, id: campaign.voiceLeadMagnet._id });

const simpleDateString = date =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

// !! ###############################################################
const Dashboard = ({ userData, onUserDataChange, onStateChange }) => {
  const [feedback, setFeedback] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [prefsChanged, setPrefsChanged] = useState(false);
  const leads = useMemo(() => {
    if (!userData.leads || !userData.leads.length) return [];
    // sorting leads by date created, descending
    // slice added to make it functional because early javascript
    return userData.leads
      .slice()
      .sort((a, b) => parseInt(b.created) - parseInt(a.created))
      .slice(0, 5);
  }, [userData.leads]);
  const leadsThisMonth = useMemo(() => {
    if (!userData.leads || !userData.leads.length) return 0;
    const endDate = new Date().valueOf();
    const startDate = new Date().setDate(0);
    return leadsBetweenDates({ leads: userData.leads, startDate, endDate })
      .length;
  }, [userData.leads]);

  const campaigns = useMemo(() => {
    if (!userData.campaigns || !userData.campaigns.length) return [];
    const result = userData.campaigns
      .map(campaign => {
        const listing = userData.listings.find(
          listing => listing._id === campaign.listingID
        );
        return {
          ...campaign,
          count: campaignCount({ campaign, userData }),
          listingName: listing ? listing.uniqueName : "Deleted Listing"
        };
      })
      .sort((a, b) => parseInt(b.count) - parseInt(a.count))
      .slice(0, 5);
    console.log(result);
    return result;
  }, [userData]);

  const submitFeedback = async () => {
    try {
      await apiSubmitFeedback({
        feedback,
        token: getToken()
      });
      setFeedbackMessage(
        "Thanks for submitting your feedback to us! If needed a member of our team will reach out to you."
      );
      setFeedback("");
    } catch (error) {
      setFeedbackMessage(error.message);
    }
  };

  const handleSettingsUpdate = async update => {
    try {
      const result = await apiUpdateSettings({
        token: getToken(),
        userID: userData._id,
        ...userData.settings,
        ...update
      });
      onUserDataChange({
        key: "settings",
        value: result
      });
    } catch (error) {
      console.log(error);
      onStateChange({
        message: {
          type: "error",
          message:
            "There was an error updating your settings. Please try again.",
          id: "dashboard-error-message"
        }
      });
    }
  };

  const leadList = leads.map((lead, index) => (
    <span className="dash-list-item box-shadow-subtle" key={index}>
      <span className="dash-list-sub-item w25">
        {lead.name ? lead.name : "no name"}
      </span>
      <span className="dash-list-sub-item w25">
        {lead.phone ? lead.phone : "no phone"}
      </span>
      <span className="dash-list-sub-item w25">
        {lead.email ? lead.email : "no email"}
      </span>
      <span className="dash-list-sub-item w25">
        {lead.sourceType ? lead.sourceType : "unknown"}
      </span>
    </span>
  ));

  const campaignList = campaigns.map((campaign, index) => (
    <span className="dash-list-item box-shadow-subtle" key={index}>
      <span className="dash-list-sub-item w33">{campaign.listingName}</span>
      <span className="dash-list-sub-item w33">{campaign.count} leads</span>
      <span className="dash-list-sub-item w33">
        {campaign.dateCreated
          ? simpleDateString(new Date(parseInt(campaign.dateCreated)))
          : "Unknown"}
      </span>
    </span>
  ));

  return (
    <section id="dashboard">
      <div
        id="dashboard-goal"
        className="box-content flex-row-center padding-1rem"
      >
        {userData.settings && userData.settings.monthlyLeadGoal === 0 ? (
          <SetNewGoal
            suggestedGoal="...your lead goal"
            onSubmit={goal => handleSettingsUpdate({ monthlyLeadGoal: goal })}
          ></SetNewGoal>
        ) : (
          <Goal
            leadNumber={leadsThisMonth}
            goalNumber={
              userData.settings ? userData.settings.monthlyLeadGoal : 0
            }
          ></Goal>
        )}
      </div>
      {userData.leads && userData.leads.length ? (
        <div id="dashboard-graph" className="box-content flex-center-column">
          <h2 className="blue-text">New Leads Over 30 Days</h2>
          <LeadsOverTime leads={userData.leads}></LeadsOverTime>
        </div>
      ) : (
        <SetNotifyPrefs
          id="dashboard-graph"
          settings={userData.settings}
          onSettingChange={newSettings => {
            setPrefsChanged(true);
            onUserDataChange({
              key: "settings",
              value: {
                ...userData.settings,
                ...newSettings
              }
            });
          }}
          email={userData.email}
          phone={userData.contact.phone}
          onSubmit={() => {
            handleSettingsUpdate(userData.settings);
            setPrefsChanged(false);
          }}
          prefsChanged={prefsChanged}
        ></SetNotifyPrefs>
      )}
      <div
        id="dashboard-leads"
        className="box-content flex-center-start padding-1rem"
      >
        {leads.length ? (
          <>
            <h2 className="blue-text">Recent Leads</h2>
            <div id="lead-list-wrapper">{leadList}</div>
          </>
        ) : (
          <>
            <h2 className="blue-text">Set up your lead pipeline...</h2>
            <ol className="flex-center-space-around h100">
              <li
                className="attention-link"
                onClick={() =>
                  onStateChange({
                    navLink: "web"
                  })
                }
              >
                Choose your website subdomain.
                {userData.webLMs && userData.webLMs.length ? (
                  <CheckCircleIcon></CheckCircleIcon>
                ) : null}
              </li>
              <li
                className="attention-link"
                onClick={() =>
                  onStateChange({
                    navLink: "listings"
                  })
                }
              >
                Import or create some listings.{" "}
                {userData.listings && userData.listings.length ? (
                  <CheckCircleIcon></CheckCircleIcon>
                ) : null}
              </li>
              <li
                className="attention-link"
                onClick={() =>
                  onStateChange({
                    navLink: "ad-wizard"
                  })
                }
              >
                Create an ad campaign.
                {userData.campaigns && userData.campaigns.length ? (
                  <CheckCircleIcon></CheckCircleIcon>
                ) : null}
              </li>
            </ol>
          </>
        )}
      </div>
      {campaigns.length ? (
        <div
          id="dashboard-campaigns"
          className="box-content flex-center-column"
        >
          <h2 className="blue-text">Top Campaigns</h2>
          <div id="campaign-list-wrapper">{campaignList}</div>
        </div>
      ) : null}
      <div
        id="dashboard-feed"
        className="box-content flex-center-column position-rel"
      >
        <h2>We want your feedback!</h2>
        <span className="w80 border-attention margin1rem-top">
          {feedbackMessage
            ? feedbackMessage
            : `Thanks for trying out the Pipeline Pro Tools 2.0 Beta. We'd love to
          get your feedback, bug reports, success stories, or anything else!`}
        </span>
        <PptTextArea
          labelText="Feedback"
          name="feedback"
          className="w80 align-center"
          rows={7}
          value={feedback}
          onChange={e => {
            const value = e.target.value;
            setFeedback(value);
          }}
        ></PptTextArea>
        <button
          className="action-button abs-lower-right-1rem"
          onClick={submitFeedback}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default Dashboard;
