import apiAddress from "./apiAddress";

const apiCreateUser = async ({
  email,
  website,
  company,
  firstName,
  lastName,
  phone,
  zip
}) => {
  const mutation = `mutation {
        createUser(user: {
          email: "${email}"
          website: "${website}"
          contact: {
            firstName:"${firstName}"
            lastName:"${lastName}"
            email:"${email}"
            phone:"${phone}"
            zip:${zip ? zip : null}
            company:"${company}"
          }    
        }) {
          error
          message
          code
          refreshToken
          id
          payload
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);

    if (json.data && json.data.createUser.error) {
      throw new Error(json.data.createUser.message);
    }
    return json.data.createUser;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default apiCreateUser;
