import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiSubmitFeedback = async ({ token, feedback }) => {
  const mutation = `mutation {
        data: submitFeedback(feedback:"${encodeURIComponent(feedback)}"){
          error
          message
          id
        }
      }`;
  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiSubmitFeedback;
