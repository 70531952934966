import React from "react";
import LeadMagnetCard from "./LeadMagnetCard";
import apiUpdateTwilioLM from "../../../helpers/api/apiUpdateTwilioLM";
import apiArchiveTwilioLM from "../../../helpers/api/apiArchiveTwilioLM";
import { getToken } from "../../../helpers/tokenManager";

const LeadMagnetView = ({ userData, onUserDataChange, lmType }) => {
  const handleListingChange = async ({ id, uniqueName }) => {
    const listing = userData.listings.find(
      listing => listing.uniqueName === uniqueName
    );
    const twilioLM = userData.twilioLMs.find(
      leadMagnet => leadMagnet._id === id
    );
    await apiUpdateTwilioLM({
      token: getToken(),
      id,
      ...twilioLM,
      listingID: listing._id
    });
    onUserDataChange({
      key: "twilioLMs",
      value: userData.twilioLMs.map(leadMagnet =>
        leadMagnet._id === id
          ? {
              ...leadMagnet,
              listingID: listing._id
            }
          : leadMagnet
      )
    });
  };

  const handleResponseTextChange = async ({ id, responseText }) => {
    const twilioLM = userData.twilioLMs.find(
      leadMagnet => leadMagnet._id === id
    );

    await apiUpdateTwilioLM({
      token: getToken(),
      id,
      ...twilioLM,
      responseText
    });
    onUserDataChange({
      key: "twilioLMs",
      value: userData.twilioLMs.map(leadMagnet =>
        leadMagnet._id === id
          ? {
              ...leadMagnet,
              responseText
            }
          : leadMagnet
      )
    });
  };

  const handleListingDelete = async ({ id }) => {
    const result = await apiArchiveTwilioLM({ id, token: getToken() });
    if (result)
      onUserDataChange({
        key: "twilioLMs",
        value: userData.twilioLMs.filter(leadMagnet => leadMagnet._id !== id)
      });
  };

  // matches type and creates components
  const leadMagnetCards = userData.twilioLMs.reduce(
    (result, item) =>
      item.lmType === lmType
        ? [
            ...result,
            <LeadMagnetCard
              onListingChange={handleListingChange}
              onResponseTextChange={handleResponseTextChange}
              onConfirmedDelete={handleListingDelete}
              key={item._id}
              {...item}
              listingName={
                item.listingID &&
                userData.listings.some(
                  listing => listing._id === item.listingID
                )
                  ? userData.listings.find(
                      listing => listing._id === item.listingID
                    ).uniqueName
                  : ""
              }
              listings={userData.listings.map(listing => listing.uniqueName)}
              lmType={lmType}
            ></LeadMagnetCard>
          ]
        : result,
    []
  );

  return <>{leadMagnetCards}</>;
};

export default LeadMagnetView;
