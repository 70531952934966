import React from "react";
import PptCheckbox from "../../components/PptCheckbox";
import PptSelection from "../../components/PptSelection";
import themes from "../../helpers/themes";
import ThemeColors from "../../Account/ThemeColors";

const SetNotifyPrefs = ({
  onSubmit,
  id,
  settings,
  onSettingChange,
  email,
  phone,
  prefsChanged = false
}) => {
  return (
    <div className="flex-table flex-table-2col box-content" id={id}>
      <div className="flex-table-item flex-center-column">
        <h3 className="blue-text">Send New Lead Notifications?</h3>
        <PptCheckbox
          name="notify-email"
          onChange={e => onSettingChange({ notifyEmail: e.target.checked })}
          labelText={email}
          isChecked={settings.notifyEmail}
          className="w16rem"
        ></PptCheckbox>
        <PptCheckbox
          name="notify-sms"
          onChange={e => onSettingChange({ notifySms: e.target.checked })}
          labelText={phone}
          isChecked={settings.notifySms}
          className="w16rem"
        ></PptCheckbox>
      </div>
      <div className="flex-table-item flex-center-column overflow-visible">
        <h3 className="blue-text">Display Theme for Single Prop & Websites</h3>
        <PptSelection
          label="Single Property & Web Theme"
          className="w16rem"
          items={themes.map(theme => theme.name)}
          selection={settings.theme}
          onChange={selection => onSettingChange({ theme: selection })}
        ></PptSelection>
        <ThemeColors
          themeName={settings.theme}
          className="w16rem"
        ></ThemeColors>
      </div>
      <button
        className="action-button abs-lower-right-1rem green-text"
        onClick={onSubmit}
        disabled={!prefsChanged}
      >
        Save
      </button>
    </div>
  );
};

export default SetNotifyPrefs;
