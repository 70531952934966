import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";

const CreateListingCard = ({ onClick }) => (
  <div
    onClick={onClick}
    id="create-listing-card"
    className="flex-center-column alt-bg box-shadow-subtle"
  >
    <span id="create-listing-icon" className="h10rem blue-text">
      <AddBoxIcon
        style={{
          height: "10rem",
          width: "10rem"
        }}
      ></AddBoxIcon>
    </span>
    <h3 className="dark-text">Create New Listing</h3>
  </div>
);

export default CreateListingCard;
