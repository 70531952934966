const formDataDefaults = {
  uniqueName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  price: 0,
  beds: 1,
  fullBaths: 1,
  halfBaths: 0,
  description: "",
  style: "",
  subdivision: "",
  keywords: [],
  googlePlaceId: "",
  coordinates: [],
  images: [],
};

export { formDataDefaults };
