import React, { useState } from "react";
import apiZillowListing from "../../../helpers/api/apiZillowListing";
import { getToken } from "../../../helpers/tokenManager";
import LoadingWrapper from "../../../components/LoadingWrapper";
import {
  zillowListingMatch,
  zillowHomeDetailsUrl
} from "../../../helpers/zillowValidators";

const ZillowImporter = ({ onDismiss, onResult, onError }) => {
  const [zillowUrl, setZillowUrl] = useState("");
  const [urlInputTimeout, setUrlInputTimeout] = useState(null);
  const [loading, setLoading] = useState(false);

  const beginUrlTimeout = url => {
    clearTimeout(urlInputTimeout);
    setUrlInputTimeout(
      setTimeout(async () => {
        if (!zillowListingMatch(url)) {
          onError(
            new Error(
              "That doesn't look like a Zillow URL.\nShould start with 'https://www.zillow.com/homes/ or https://www.zillow.com/homedetails/'"
            )
          );
          return;
        }
        setLoading(true);
        try {
          const result = await apiZillowListing({
            url: zillowHomeDetailsUrl(url),
            token: getToken()
          });
          if (!result)
            throw new Error(
              "Sorry there was an error contacting Zillow. You can try again in a few moments."
            );
          onResult(result);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (onError(error)) onError(error);
        }
      }, 500)
    );
  };

  const urlChangeHandler = e => {
    const value = e.target.value;
    setZillowUrl(value);

    beginUrlTimeout(value);
  };

  return (
    <form className="box-content panel-content flex-center-column position-rel">
      <label className="ppt-input-container w90" htmlFor="zillow-url">
        <span className="ppt-input-label">Zillow Listing URL</span>
        <input
          className="ppt-input w90 transform-up-half-rem"
          placeholder="https://www.zillow.com/***"
          name="zillow-url"
          value={zillowUrl}
          onChange={urlChangeHandler}
        ></input>
      </label>
      <button
        className="abs-top-left-1rem background-transparent"
        onClick={onDismiss}
        type="button"
      >
        <span aria-label="close" role="img">
          ❌
        </span>
      </button>
      <LoadingWrapper loading={loading}></LoadingWrapper>
    </form>
  );
};

export default ZillowImporter;
