import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiChangePassword = async ({
  userEmail,
  token,
  prevPassword,
  newPassword
}) => {
  const mutation = `mutation {
      data: updateCredentials(
        prevCredentials: { email: "${userEmail}", password: "${prevPassword}"}
        newCredentials: { email: "${userEmail}", password: "${newPassword}"}
      ) {
        error
        message
        code
      }
    }
  `;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiChangePassword;
