import React, { useCallback, useState, useEffect } from "react";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useDropzone } from "react-dropzone";
import { getS3Url } from "../../helpers/s3Cache";

const AgentProfilePic = ({ agentProfilePic, onNewUpload }) => {
  const [signedUrl, setSignedUrl] = useState("");
  useEffect(() => {
    const getSignedUrl = async () => {
      if (!agentProfilePic) return;
      const url = await getS3Url({ key: agentProfilePic.keyName });
      setSignedUrl(url);
    };
    getSignedUrl();
  }, [agentProfilePic]);
  const onDrop = useCallback(
    async acceptedFiles => {
      // eslint-disable-next-line
      if (onNewUpload) {
        acceptedFiles.forEach(onNewUpload);
      }
    },
    [onNewUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png"
  });

  const renderEmpty = () => (
    <>
      <AddPhotoAlternateIcon className="dark-text"></AddPhotoAlternateIcon>
      <h2 className="dark-text w10rem text-align-center">
        Upload Profile Picture
      </h2>
    </>
  );

  return (
    <div
      id="agent-profile-wrapper"
      className={`box-content flex-center-column w12rem h12rem margin1rem-top circle 
      border1rem dark-border-light-bg hover-border-blue ${
        isDragActive ? "hover-border-dashed" : ""
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()}></input>
      {agentProfilePic ? (
        <img id="agent-profile-image" src={signedUrl} alt="agent profile"></img>
      ) : (
        renderEmpty()
      )}
    </div>
  );
};

export default AgentProfilePic;
