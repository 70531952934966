import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const AutosaveTextArea = ({
  text,
  onTextChange,
  isSaving = false,
  title = "Input",
  rows = 10,
  className = "",
  clickToCopy = false
}) => {
  const [storyText, setStoryText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setStoryText(text);
  }, [text]);

  useEffect(() => {
    if (isCopied) setTimeout(() => setIsCopied(false), 3000);
  }, [isCopied]);

  const handleInput = e => {
    const value = e.target.value;
    clearTimeout(typingTimeout);
    setStoryText(value);
    setIsTyping(true);
    setTypingTimeout(
      setTimeout(() => {
        onTextChange({ value });
        setIsTyping(false);
      }, 1000)
    );
  };

  const renderStatus = () => {
    if (isSaving) {
      return (
        <span className="abs-top-right green-text text-small flex-row-center">
          {"Saving..."}
          <AutorenewIcon></AutorenewIcon>
        </span>
      );
    } else if (isTyping) {
      return (
        <span className="abs-top-right blue-text text-small flex-row-center">
          {"Typing..."}
          <KeyboardIcon></KeyboardIcon>
        </span>
      );
    } else {
      return (
        <span className="abs-top-right green-text text-small flex-row-center">
          {"Saved "}
          <CheckBoxIcon></CheckBoxIcon>
        </span>
      );
    }
  };

  const renderCopy = () => {
    return (
      <CopyToClipboard
        text={storyText}
        onCopy={() => setIsCopied(true)}
        onBlur={() => setIsCopied(false)}
      >
        <span
          onBlur={() => setIsCopied(false)}
          className={`abs-top-left text-small flex-row-center ${
            isCopied ? "green-text" : "blue-text"
          }`}
        >
          <FileCopyIcon></FileCopyIcon>
          {isCopied ? "Copied!" : "Copy Text"}
        </span>
      </CopyToClipboard>
    );
  };

  return (
    <div
      className={`flex-center-column padding-1rem position-rel ${className}`}
    >
      <div className="ppt-input-container margin-reset w90percent">
        <h2 className="margin1rem text-align-center w100percent">{title}</h2>
        <textarea
          className="ppt-text-area transform-up-half-rem w100percent"
          rows={rows}
          value={storyText}
          onChange={handleInput}
        ></textarea>
      </div>
      {renderStatus()}
      {clickToCopy ? renderCopy() : null}
    </div>
  );
};

export default AutosaveTextArea;
