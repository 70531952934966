import React, { useState, useEffect } from "react";
import { useCombobox } from "downshift";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

const ListingSelection = ({
  onChange,
  listings,
  selection,
  label = "Listing"
}) => {
  const [listingItems, setListingItems] = useState([]);
  useEffect(() => {
    setListingItems(listings);
  }, [listings]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items: listingItems,
    onInputValueChange: ({ inputValue }) => {
      setListingItems(
        listings.filter(item =>
          item.toLowerCase().startsWith(inputValue.toLowerCase())
        )
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onChange) onChange(selectedItem);
    },
    initialSelectedItem: selection ? selection : null
  });

  return (
    <>
      <div
        {...getComboboxProps({ className: "ppt-input-container w20rem z10" })}
      >
        <label {...getLabelProps()}>
          <span className="ppt-input-label">{label}</span>
        </label>
        <input
          {...getInputProps({
            className: "ppt-input w90"
          })}
        ></input>
        <button
          {...getToggleButtonProps({ className: "ppt-select-toggle-button" })}
          aria-label="toggle menu"
        >
          <MenuOpenIcon></MenuOpenIcon>
        </button>

        <ul {...getMenuProps({ className: "ppt-select-menu" })}>
          {isOpen &&
            listingItems.map((listing, index) => (
              <li
                {...getItemProps({
                  item: listing,
                  index,
                  key: index,
                  className:
                    highlightedIndex === index
                      ? "ppt-select-menu-item ppt-select-menu-active"
                      : "ppt-select-menu-item"
                })}
              >
                {listing}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default ListingSelection;
