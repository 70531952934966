import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const addQuotes = word => `"${word}"`;

const apiUpdateAdKeywords = async ({ token, keywords }) => {
  const mutation = `mutation {
        data: updateUser(userUpdate:{
          adKeywords:[${[...keywords.map(keyword => addQuotes(keyword))]}]
        }){
          error
          message
          code
          id
        }
      }`;
  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    if (result.data.error) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateAdKeywords;
