import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import apiGenerateTwilioLM from "../../../helpers/api/apiGenerateTwilioLM";
import { getToken } from "../../../helpers/tokenManager";
import CustomLeadMagnet from "./CustomLeadMagnet";

const LeadMagnetEditor = ({
  appState,
  onStateChange,
  userData,
  onUserDataChange,
  lmType
}) => {
  const [editMode, setEditMode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEditModeChange = mode => setEditMode(mode);

  const handleGenerateClick = async () => {
    if (loading) return;
    setLoading(true);
    const result = await apiGenerateTwilioLM({
      token: getToken(),
      userID: userData._id,
      listingID: null,
      responseText: "",
      notify: {
        email: userData.email,
        sms: ""
      },
      lmType
    });
    onUserDataChange({
      key: "twilioLMs",
      value: [result, ...userData.twilioLMs]
    });
    setLoading(false);
  };

  const renderActionButtons = () => (
    <div className="box-content padding-1rem flex-row-space-evenly w50percent">
      <button
        className="action-button"
        onClick={() => handleEditModeChange("generate")}
      >
        auto generate
      </button>
      <button
        className="action-button"
        onClick={() => handleEditModeChange("custom")}
      >
        create custom
      </button>
    </div>
  );

  const renderGenerator = () => (
    <div className="box-content padding-1rem flex-row-space-evenly w50percent">
      <button
        className="lead-magnet-action"
        onClick={() => handleEditModeChange("")}
      >
        <ArrowBackIcon></ArrowBackIcon>
        <span>Back</span>
      </button>
      <span className="w12rem text-small">
        Generate a new lead magnet with an assigned phone number and code.
      </span>
      <button className="lead-magnet-action" onClick={handleGenerateClick}>
        <AddCircleIcon></AddCircleIcon>
        <span>{loading ? "Loading..." : "Generate"}</span>
      </button>
    </div>
  );

  const renderCustom = () => (
    <CustomLeadMagnet
      userData={userData}
      onUserDataChange={onUserDataChange}
      onBack={() => setEditMode("")}
      lmType={lmType}
      appState={appState}
      onStateChange={onStateChange}
    ></CustomLeadMagnet>
  );

  return (
    <>
      {editMode === "" ? renderActionButtons() : null}
      {editMode === "generate" ? renderGenerator() : null}
      {editMode === "custom" ? renderCustom() : null}
    </>
  );
};

export default LeadMagnetEditor;
