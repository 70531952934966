import React from "react";
import AdWizard from "./AdWizard";
import Campaigns from "./Campaigns";

const Ads = props => (
  <>
    {props.appState && props.appState["ad-wizard"].panel === "ads" ? (
      <Campaigns {...props}></Campaigns>
    ) : null}
    {props.appState && props.appState["ad-wizard"].panel === "wizard" ? (
      <AdWizard {...props}></AdWizard>
    ) : null}
  </>
);

export default Ads;
