import apiAddress from "./apiAddress";

const apiCreateListing = async ({
  token,
  email,
  userID,
  uniqueName,
  address,
  address2,
  city,
  state,
  zip,
  price = 0,
  beds = 0,
  fullBaths = 0,
  halfBaths = 0,
  description,
  style,
  subdivision,
  keywords,
  googlePlaceId,
  coordinates,
  images = []
}) => {
  const imagesGql = images.map(
    image => `
    {
      bucketName:"ppt",
      keyName:"${image.key}"
    }
  `
  );

  const mutation = `mutation {
        createListing(listing:{
          userID:"${userID}"
          userEmail:"${email}"
          uniqueName:"${uniqueName}"
          address:"${address}"
          address2:"${address2}"
          city:"${city}"
          state:"${state}"
          zip: "${zip}"
          beds:${beds}
          halfBaths:${halfBaths}
          fullBaths:${fullBaths}
          price:${price}
          style:"${style}"
          subdivision:"${subdivision}"
          keywords:[${keywords}]
          description: "${description}"
          googlePlaceId: "${googlePlaceId}"
          geoJsonPoint: {
            type:"Point"
            coordinates:[${coordinates}]
          }
          images: [${imagesGql}]
        }){
          error
          message
          code
          id
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);
    if (json.data && json.data.createListing.error) {
      throw new Error(json.data.createListing.message);
    }
    return json.data.createListing;
  } catch (error) {
    throw error;
  }
};

export default apiCreateListing;
