const themes = [
  {
    name: "Pipeline",
    className: "theme-pipeline"
  },
  {
    name: "Keller Williams",
    className: "theme-keller-williams"
  },
  {
    name: "Century 21",
    className: "theme-century-21"
  },
  {
    name: "Sothebys",
    className: "theme-sothebys"
  },
  {
    name: "Remax",
    className: "theme-remax"
  },
  {
    name: "Berkshire",
    className: "theme-berkshire"
  },
  {
    name: "Coldwell",
    className: "theme-coldwell"
  },
  {
    name: "Exit Realty",
    className: "theme-exit-realty"
  },
  {
    name: "Weichert",
    className: "theme-weichert"
  },
  {
    name: "Era",
    className: "theme-era"
  },
  {
    name: "Exp",
    className: "theme-exp"
  }
];

export default themes;
