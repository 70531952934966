import React, { useState } from "react";
import Logo from "../Header/Logo";
import PptInput from "../components/PptInput";
import LoadingWrapper from "../components/LoadingWrapper";
import apiImportUser from "../helpers/api/apiImportUser";
import { signupSubmit } from "../helpers/signupHelper";
import { setAuthCookie } from "../helpers/authHelper";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import "./signup.css";

const SignUp = props => {
  const [signupPhase, setSignupPhase] = useState("invite");
  const [inviteData, setInviteData] = useState({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
    message: "Please enter your invite code and email to get started."
  });
  const [signupData, setSignupData] = useState({
    email: "",
    startDate: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    website: "",
    zip: "",
    message: "",
    submitted: false
  });
  const [loading, setLoading] = useState(false);

  const handleSignupChange = e => {
    const { name, value } = e.target;
    setSignupData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLoginClick = e => {
    const { history } = props;
    history.push("/login");
  };

  const handleAnimationEnd = () => {
    const { history } = props;
    history.push("/");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const submitOkay = await signupSubmit({ ...signupData, ...inviteData });
      if (submitOkay) {
        setSignupData(prev => ({
          ...prev,
          error: false,
          submitted: true
        }));
        setAuthCookie(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setSignupData(prev => ({
        ...prev,
        error: true,
        errorMessage: err.message
      }));
      setLoading(false);
    }
  };

  const handleInviteInput = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!inviteData.email) throw new Error("Please input your email address");
      if (!inviteData.code) throw new Error("Please input your invite code.");
      const inviteResult = await apiImportUser({
        email: inviteData.email,
        inviteCode: inviteData.code
      });
      if (!inviteResult)
        throw new Error("Sorry, there was a problem connecting to our server.");
      const {
        email,
        startDate,
        contact: {
          company,
          phone,
          firstName,
          lastName,
          city,
          state,
          zip,
          address,
          address2
        }
      } = inviteResult;
      setSignupData({
        email,
        company,
        startDate,
        phone,
        firstName,
        lastName,
        city,
        state,
        zip,
        address,
        address2
      });
      setInviteData(prev => ({
        ...prev,
        message:
          "User invite found! Please set a new password of at least 8 letters, numbers, or special characters."
      }));
      setSignupPhase("password");
      setLoading(false);
    } catch (error) {
      setInviteData(prev => ({
        ...prev,
        message: error.message
      }));
      setLoading(false);
    }
  };

  const handleNewPassword = e => {
    e.preventDefault();

    if (inviteData.password.length <= 8) {
      setInviteData(prev => ({
        ...prev,
        message: "That password is too short. "
      }));
      return;
    }
    if (inviteData.password !== inviteData.confirmPassword) {
      setInviteData(prev => ({
        ...prev,
        message: "Passwords do not match."
      }));
      return;
    }
    setSignupData(prev => ({
      ...prev,
      message:
        "Please confirm or change any details below, and then click sign up to creat your new PPT 2.0 Beta Account."
    }));
    setSignupPhase("confirm");
  };

  return (
    <form
      id="root-signup"
      onSubmit={handleSubmit}
      className={signupData.submitted ? "exit-form" : ""}
    >
      <section
        id="signup-information"
        className="box-content"
        onAnimationEnd={handleAnimationEnd}
      >
        <Logo></Logo>
        <h3 className="blue-text">Sign Up</h3>
        {signupData.error ? (
          <h4 id="signup-error-message">{signupData.errorMessage}</h4>
        ) : null}
        {signupPhase === "invite" ? (
          <div className="flex-center-column w100 h100">
            <span className="w20rem">{inviteData.message}</span>
            <PptInput
              labelText="Invite Code"
              className="w10rem"
              value={inviteData.code}
              onChange={e => {
                const value = e.target.value;
                setInviteData(prev => ({ ...prev, code: value }));
              }}
              type="text"
              required
            ></PptInput>
            <PptInput
              labelText="Email"
              className="w16rem"
              value={inviteData.email}
              onChange={e => {
                const value = e.target.value;
                setInviteData(prev => ({ ...prev, email: value }));
              }}
              type="text"
              required
            ></PptInput>
            <LoadingWrapper loading={loading}>
              <button
                className="action-button green-text"
                onClick={handleInviteInput}
              >
                next
                <NavigateNextIcon></NavigateNextIcon>
              </button>
            </LoadingWrapper>
          </div>
        ) : null}
        {signupPhase === "password" ? (
          <div className="flex-center-column w100 h100">
            <span className="w20rem">{inviteData.message}</span>
            <PptInput
              labelText="New Password"
              className="w16rem"
              value={inviteData.password}
              onChange={e => {
                const value = e.target.value;
                setInviteData(prev => ({ ...prev, password: value }));
              }}
              type="password"
              autoComplete="new-password"
            ></PptInput>
            <PptInput
              labelText="Confirm Password"
              className="w16rem"
              value={inviteData.confirmPassword}
              onChange={e => {
                const value = e.target.value;
                setInviteData(prev => ({
                  ...prev,
                  confirmPassword: value
                }));
              }}
              type="password"
              autoComplete="new-password"
            ></PptInput>

            <button
              className="action-button green-text"
              onClick={handleNewPassword}
            >
              next
              <NavigateNextIcon></NavigateNextIcon>
            </button>
          </div>
        ) : null}
        {signupPhase === "confirm" ? (
          <div id="signup-input">
            {signupData.message ? (
              <span id="signup-message" className="w20rem">
                {signupData.message}
              </span>
            ) : null}
            <input
              id="signup-firstname"
              className="ppt-input"
              placeholder="First Name"
              value={signupData.firstName}
              onChange={handleSignupChange}
              type="text"
              name="firstName"
              autoComplete="given-name"
              required
            ></input>
            <input
              id="signup-lastname"
              className="ppt-input"
              placeholder="Last Name"
              value={signupData.lastName}
              onChange={handleSignupChange}
              type="text"
              name="lastName"
              autoComplete="family-name"
              required
            ></input>
            <input
              id="signup-phone"
              className="ppt-input"
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={signupData.phone}
              onChange={handleSignupChange}
              autoComplete="tel"
              required
            ></input>
            <input
              id="signup-company"
              className="ppt-input"
              type="text"
              name="company"
              placeholder="Company"
              value={signupData.company}
              onChange={handleSignupChange}
              autoComplete="organization"
            ></input>
            <input
              id="signup-website"
              className="ppt-input"
              type="url"
              name="website"
              placeholder="Website"
              value={signupData.website}
              onChange={handleSignupChange}
              autoComplete="url"
            ></input>
            <input
              id="signup-zipcode"
              className="ppt-input"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              name="zip"
              placeholder="Zip Code"
              value={signupData.zip}
              onChange={handleSignupChange}
              autoComplete="postal-code"
            ></input>
          </div>
        ) : null}
      </section>
      <section id="signup-actions" className="box-content">
        <button
          type="button"
          className="headline-button headline-button-secondary"
          onClick={handleLoginClick}
        >
          login
        </button>

        <button
          type="submit"
          className="headline-button"
          disabled={signupPhase !== "confirm"}
        >
          sign up
        </button>
      </section>
    </form>
  );
};

export default SignUp;
