import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiUpdateWebLM = async ({
  id,
  token,
  userID,
  uniqueName,
  callToAction,
  callToAction2,
  titleStory,
  title,
  theme
}) => {
  const mutation = `mutation {
        data: updateWebLeadMagnet(id:"${id}", update:{
          userID:"${userID}"
          uniqueName:"${uniqueName}"
          callToAction:"${encodeURIComponent(callToAction)}"
          callToAction2:"${encodeURIComponent(callToAction2)}"
          titleStory:"${encodeURIComponent(titleStory)}"
          title:"${encodeURIComponent(title)}"
          theme:"${theme}"
        }){
          error
          message
          code
          id
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateWebLM;
