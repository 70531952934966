import apiAddress from "./apiAddress";

const apiConnectCredentials = async ({ email, token }) => {
  const mutation = `mutation {
        connectCredentials(email:"${email}"){
          error
          message
          code
          refreshToken
          id
          payload
        }
      }`;
  const authHeader = `Bearer ${token}`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: authHeader
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);
    if (json.data && json.data.connectCredentials.error) {
      throw new Error(json.data.connectCredentials.message);
    }
    return json.data.connectCredentials;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default apiConnectCredentials;
