import React from "react";

const Logo = () => {
  return (
    <img
      className="ppt-logo"
      src="images/pipeline-pro-tools-logo.png"
      alt="PipelineProTools Logo"
    />
  );
};

export default Logo;
