import apiAddress from "./apiAddress";

const apiUpdateListing = async ({
  listingID,
  token,
  email,
  userID,
  uniqueName,
  address,
  city,
  state,
  zip,
  price = 0,
  beds = 0,
  fullBaths = 0,
  halfBaths = 0,
  description,
  style,
  subdivision,
  googlePlaceId,
  images = []
}) => {
  const imagesGql = images.map(
    image => `
        {
          bucketName:"ppt",
          keyName:"${image.key}"
        }
      `
  );

  const mutation = `mutation {
        updateListing(listingID: "${listingID}", update: {
          userID:"${userID}"
          userEmail:"${email}"
          uniqueName:"${uniqueName}"
          googlePlaceId:"${googlePlaceId}"
          address:"${address}"
          city:"${city}"
          state:"${state}"
          zip:"${zip}"
          images:[${imagesGql}],
          beds:${beds}
          fullBaths:${fullBaths}
          halfBaths:${halfBaths}
          price: ${price ? price : 0}
          style:"${style}"
          subdivision:"${subdivision}"
          description:"${description}"
        }) {
            error
            message
            code
            refreshToken
            id
            payload
          }
      } `;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);
    if (json.data && json.data.updateListing.error) {
      throw new Error(json.data.updateListing.message);
    }
    return json.data.updateListing;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateListing;
