import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiUpdateContact = async ({
  userID,
  website,
  firstName,
  lastName,
  email,
  phone,
  company,
  address,
  address2,
  city,
  state,
  zip = 0,
  country = "usa",
  token
}) => {
  const mutation = `mutation {
        data: updateUser(userUpdate:{
          id:"${userID}"
          website:"${website}"
          contact:{
            firstName:"${firstName}"
            lastName:"${lastName}"
            email:"${email}"
            phone:"${phone}"
            company:"${company}"
            address:"${address}"
            address2:"${address2}"
            city:"${city}"
            state:"${state}"
            zip:${zip}
            country:"${country}"
          }
        }){
          error
          message
          code
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateContact;
