import apiAddress from "./apiAddress";

const apiPostSignedS3Url = async ({
  userID,
  key,
  token,
  keyPrefix,
  contentType,
}) => {
  if (!userID) throw new Error("UserID is required");
  if (!key) throw new Error("File key is required");
  if (!token) throw new Error("Token is required");

  const query = `{
        s3posturl(params:{
          userID:"${userID}"
          key:"${key}"
          contentType:"${contentType}"
          keyPrefix:"${keyPrefix}"
        })
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query,
      }),
    });
    const json = await result.json();
    console.log(json);
    if (!json || !json.data || !json.data.s3posturl)
      throw new Error("Problem with s3 uploader.");
    const parsedResult = JSON.parse(json.data.s3posturl);
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

const apiGetSignedS3Url = async ({ key }) => {
  const query = `{
    s3geturl(params:{
      key:"${key}"
    })
  }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      body: JSON.stringify({
        query,
      }),
    });
    const json = await result.json();
    return json.data.s3geturl;
  } catch (error) {
    throw error;
  }
};

const apiPutS3Images = async ({ images, userID, token }) => {
  if (!images || !userID || !token)
    throw new Error("missing required arguements for image s3 put");

  const queryItems = images.map((imageUrl, index) => {
    // regex magic: get the string following the last '/'
    const imageKey = "listings/" + /[^/]*$/.exec(imageUrl)[0];
    return `image${index}: s3putItemFromUrl(params: {
      userID: "${userID}"
      key: "${imageKey}"
      url: "${imageUrl}"
    }) {
      key
      url
    }`;
  });

  const mutation = `mutation { ${queryItems.join(" ")} }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: mutation,
      }),
    });
    const json = await result.json();
    return Object.values(json.data);
  } catch (error) {
    throw error;
  }
};

export { apiPostSignedS3Url, apiGetSignedS3Url, apiPutS3Images };
