import apiAddress from "./apiAddress";

const apiSearchLocalPhoneNumbers = async ({ token, areaCode }) => {
  const query = `{
        searchLocalPhoneNumbers(search:{areaCode:"${areaCode}"}) {
            phoneNumber
            friendlyName
            locality
            region
            cost
          }
        }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    return json.data.searchLocalPhoneNumbers;
  } catch (error) {
    throw error;
  }
};

export default apiSearchLocalPhoneNumbers;
