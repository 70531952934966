import React from "react";

const ActionsToggle = ({ actions, selectedActionIndex, onActionSelect }) => {
  if (!actions || !Array.isArray(actions)) return null;
  const actionButtons = actions.map(({ name, key }, index) => (
    <button
      key={key}
      onClick={() => onActionSelect(index)}
      className={
        selectedActionIndex === index
          ? `headline-button button-hover-right-red button-right-selected`
          : `headline-button-secondary button-hover-right-red`
      }
    >
      {name}
    </button>
  ));
  return (
    <div className="box-content right-action-buttons">{actionButtons}</div>
  );
};

export default ActionsToggle;
