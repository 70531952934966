import apiAddress from "./apiAddress";

const getUser = async ({ token, email }) => {
  const query = `{
        user {
          _id
          credentials
          email
          website
          twilioAccountConnected
          contact {
            firstName
            lastName
            email
            phone
            company 
            address
            address2
            city
            state
            zip
            country
          }
          billing {
            exp
            last4
            expMonth
            expYear
            frequency
            nextCharge
          }
          status
          leads {
            _id
            name
            email
            phone
            source
            sourceDesc
            sourceType
            contact {
              firstName
              lastName
              email
              phone
              company
              address
              address2
              city
              state
              zip
              country
            }
            value
            created
            phase
            tags
            history {
              timestamp
              type
              content
              notes
            }
          }
          listings {
            _id
            geoJsonPoint {
              type
              coordinates
            }
            description
            userID
            userEmail
            uniqueName
            googlePlaceId
            created
            price
            address
            address2
            city
            state
            zip
            country
            images {
              bucketName
              keyName
              path
              uri
            }
            beds
            style
            subdivision
          }
          singlePropLMs {
            uniqueName
            leads
            listing {
              userID
              userEmail
            }
            dateCreated
            notify {
              email
              sms
            }
            tags
          }
          webLMs {
            _id
            uniqueName
            userID
            leads {
              name
              _id
              email
              phone
              source
              sourceDesc
            }
            dateCreated
            theme
            title
            titleStory
            callToAction
            callToAction2
          }
          twilioLMs {
            _id
            phoneNumber
            code
            dateCreated
            responseText
            listingID
            leads
            notify {
              email
              sms
            }
            uniqueHits
            lmType
          }
          startDate
          settings {
            notifyEmail
            notifySms
            theme 
            monthlyLeadGoal
            licensedRealtor
          }
          agentProfilePic {
            bucketName
            keyName
            path
            uri
          }
          agentLogo {
            bucketName
            keyName
            path
            uri
          }
          campaigns {
            _id
            userID
            listingID
            smsLeadMagnet {
              _id
            }
            voiceLeadMagnet {
              _id
            }
            name
            adCopy
            adKeywords
            dateCreated
          }
          adKeywords
        }
        linkedPhoneNumbers {
          phoneNumber
          friendlyName
        }
       
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();

    return json.data;
  } catch (error) {
    throw error;
  }
};

export { getUser };
