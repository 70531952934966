import apiAddress from "./apiAddress";

const apiCreateCredentials = async ({ email, password }) => {
  const mutation = `
      mutation {
          createCredentials(credentials:{
            email: "${email}"
            password:"${password}"
          }){
            error
            message
            code
            refreshToken
            id
            payload
          }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    console.log(json);

    if (json.data && json.data.createCredentials.error) {
      throw new Error(json.data.createCredentials.message);
    }
    return json.data.createCredentials;
  } catch (error) {
    throw error;
  }
};

export default apiCreateCredentials;
