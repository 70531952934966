import React, { useState, useEffect } from "react";
import ListingSelection from "../LeadMagnetView/ListingSelection";

const CustomGenerate = ({
  linkedPhoneNumbers,
  onGenerateLeadMagnet,
  loading = false
}) => {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [code, setCode] = useState("");

  useEffect(() => {
    setSelectedNumber(linkedPhoneNumbers[0]);
  }, [linkedPhoneNumbers]);

  const friendlyNumbers = linkedPhoneNumbers.map(
    ({ friendlyName }) => friendlyName
  );

  const handleChange = friendlySelection => {
    const selection = linkedPhoneNumbers.find(
      ({ friendlyName }) => friendlyName === friendlySelection
    );
    setSelectedNumber(selection);
  };

  return (
    <div className="flex-row-space-evenly w100percent">
      <div className="flex-center-column">
        <ListingSelection
          label="Phone Number"
          listings={friendlyNumbers}
          selection={friendlyNumbers.length ? friendlyNumbers[0] : null}
          onChange={handleChange}
        ></ListingSelection>
      </div>
      <div className="flex-center-column">
        <label className="ppt-input-container w7rem" htmlFor="leadMagnetCode">
          <span className="ppt-input-label">Code</span>
          <input
            className="ppt-input w90 transform-up-half-rem"
            name="leadMagnetCode"
            placeholder="1234"
            type="text"
            value={code}
            onChange={e => setCode(e.target.value)}
          ></input>
        </label>
      </div>
      <div className="flex-center-column">
        <button
          className="headline-button-light"
          onClick={() => onGenerateLeadMagnet({ code, selectedNumber })}
        >
          <span>{loading ? "Loading..." : "Generate"}</span>
        </button>
      </div>
    </div>
  );
};

export default CustomGenerate;
