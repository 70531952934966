import React from "react";

import "./loginLoader.scss";

const LoginLoader = props => {
  return (
    <>
      <h2 className="loading-label">Loading...</h2>
      <div className="loading-boxes">
        <div className="loading-box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="loading-box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="loading-box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="loading-box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default LoginLoader;
