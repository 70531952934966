import React from "react";
import themes from "../helpers/themes";

import "./theme-colors.css";

const ThemeColors = ({ themeName, className = "" }) => {
  return (
    <span
      className={`color-panel ${className} ${
        themes.find(theme => themeName === theme.name).className
      }`}
    >
      <span className="theme-title">{themeName}</span>
      <span className="color-swatch-dark"></span>
      <span className="color-swatch-light"></span>
    </span>
  );
};

export default ThemeColors;
