import React from "react";
import ListingCard from "./ListingCard";
import CreateListingCard from "./CreateListingCard";
import { getToken } from "../../../helpers/tokenManager";
import apiArchiveListing from "../../../helpers/api/apiArchiveListing";

import "../listing.css";

const ListingView = ({
  userData,
  onUserDataChange,
  appState,
  onStateChange
}) => {
  const handleListingArchive = async ({ listingID }) => {
    try {
      const result = await apiArchiveListing({ listingID, token: getToken() });
      console.log(result);
      onUserDataChange({
        key: "listings",
        value: userData.listings.filter(listing => listing._id !== listingID)
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleListingEdit = listing => {
    onStateChange({
      appKey: "listing",
      newState: {
        ...appState.listing,
        "editing-listing": listing,
        panel: "listing-editor"
      }
    });
  };

  const listingCards =
    userData && userData.listings
      ? userData.listings.map((listing, index) => (
          <ListingCard
            listing={listing}
            key={index}
            onListingArchive={handleListingArchive}
            onListingEdit={() => handleListingEdit(listing)}
            subdomain={
              userData.webLMs.length ? userData.webLMs[0].uniqueName : "www"
            }
          ></ListingCard>
        ))
      : null;

  return (
    <>
      <div className="listing-view">
        <CreateListingCard
          onClick={() =>
            onStateChange({
              appKey: "listing",
              newState: {
                ...appState.listing,
                panel: "listing-editor"
              }
            })
          }
        ></CreateListingCard>

        {listingCards}
      </div>
    </>
  );
};

export default ListingView;
