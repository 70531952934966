import React from "react";
import { appData } from "../helpers/appData";
import LoginLoader from "../components/LoginLoader";

const MainLoader = props => {
  if (Object.keys(props.userData).length === 0)
    return (
      <div className="center-content-flex">
        <LoginLoader></LoginLoader>
      </div>
    );

  const mainComponent = appData.find(app => app.appKey === props.activeTab)
    .appMain;

  return mainComponent({
    ...props
  });
};

export default MainLoader;
