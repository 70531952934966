import apiAddress from "./apiAddress";

const apiImportUser = async ({ email, inviteCode }) => {
  const query = `{
        importUser(email:"${email}", inviteCode:"${inviteCode}") {
          email
          startDate
          contact {
            company
            phone
            firstName
            lastName
            city
            state
            zip
            address
            address2
          }
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    return json.data.importUser;
  } catch (error) {
    throw error;
  }
};

export default apiImportUser;
