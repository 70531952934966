import React, { useState, useEffect } from "react";
import SimpleLeadMagnetCard from "./SimpleLeadMagnetCard";
//import LeadMagnetCard from "../LeadMagnets/LeadMagnetView/LeadMagnetCard";
import Keywords from "./Keywords";
import PhotoDownload from "./PhotoDownload";
import PptSelection from "../../components/PptSelection";

import AutosaveTextArea from "../../components/AutosaveTextArea";
import apiGenerateCampaign from "../../helpers/api/apiGenerateCampaign";
import apiUpdateCampaign from "../../helpers/api/apiUpdateCampaign";
import apiUpdateAdKeywords from "../../helpers/api/apiUpdateAdKeywords";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { getToken } from "../../helpers/tokenManager";
import { defaultKeywords } from "../../helpers/defaultCopy";
import generateAd from "../../helpers/generateAd";

import "./adWizard.css";
import PptInput from "../../components/PptInput";

const AdWizard = ({ userData, onUserDataChange, appState, onStateChange }) => {
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [smsLeadMagnet, setSmsLeadMagnet] = useState(null);
  const [voiceLeadMagnet, setVoiceLeadMagnet] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [adCopy, setAdCopy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingKeywords, setEditingKeywords] = useState(false);
  const [keywords, setKeywords] = useState([]);

  // !! respond to changes in app state by setting local state
  useEffect(() => {
    if (!userData) return;
    const activeCampaignID = appState ? appState["ad-wizard"].campaign : null;
    if (!activeCampaignID) {
      setActiveCampaign(null);
      setSmsLeadMagnet(null);
      setVoiceLeadMagnet(null);
      setSelectedListing(null);
      return;
    }

    const campaign = userData.campaigns.find(
      entry => entry._id === activeCampaignID
    );
    setActiveCampaign(campaign);
    setAdCopy(decodeURIComponent(campaign.adCopy));
    setCampaignName(campaign.name);

    const sms = userData.twilioLMs.find(
      entry => entry._id === campaign.smsLeadMagnet._id
    );
    setSmsLeadMagnet(sms);
    const voice = userData.twilioLMs.find(
      entry => entry._id === campaign.voiceLeadMagnet._id
    );
    setVoiceLeadMagnet(voice);

    const listing = userData.listings.find(
      entry => entry._id === campaign.listingID
    );
    setSelectedListing(listing);
  }, [appState, userData]);

  useEffect(() => {
    if (!keywords.length && !userData.adKeywords.length) {
      // new account, populate w/ defaults
      setKeywords(defaultKeywords);
      return;
    }
    if (!keywords.length && userData.adKeywords.length) {
      // new session, populate w/ user details
      setKeywords(userData.adKeywords);
      return;
    }
  }, [keywords, userData.adKeywords]);

  const handleCreateCampaign = async () => {
    if (isLoading) return;

    try {
      if (!selectedListing)
        throw new Error("You must select a listing to create a campaign.");

      setIsLoading(true);
      const firstResult = await apiGenerateCampaign({
        token: getToken(),
        userID: userData._id,
        listingID: selectedListing._id,
        name: campaignName,
        adCopy: ""
      });

      const { voiceLeadMagnet, smsLeadMagnet } = firstResult;
      const adCopy = encodeURIComponent(
        generateAd({
          listing: selectedListing,
          voiceLeadMagnet,
          smsLeadMagnet,
          webLM: userData.webLMs.length ? userData.webLMs[0] : null
        })
      );
      //console.log(adCopy);

      const nextResult = await apiUpdateCampaign({
        ...firstResult,
        id: firstResult._id,
        adCopy,
        token: getToken(),
        userID: userData._id
      });

      onUserDataChange({
        key: "twilioLMs",
        value: userData.twilioLMs
          ? [...userData.twilioLMs, voiceLeadMagnet, smsLeadMagnet]
          : [voiceLeadMagnet, smsLeadMagnet]
      });
      onUserDataChange({
        key: "campaigns",
        value: userData.campaigns
          ? [...userData.campaigns, { ...firstResult, ...nextResult }]
          : [{ ...firstResult, ...nextResult }]
      });

      onStateChange({
        appKey: "ad-wizard",
        newState: {
          campaign: nextResult._id,
          ...appState["ad-wizard"]
        }
      });
      setIsLoading(false);
    } catch (error) {
      onStateChange({
        appKey: "messages",
        newState: [
          ...appState.messages,
          {
            type: "error",
            id: "create-campaign-error",
            message: error.message
          }
        ]
      });
    }
  };

  const handleClearCampaign = () => {
    onStateChange({
      appKey: "ad-wizard",
      newState: {
        panel: "ads",
        campaign: null
      }
    });
  };

  const handleAdCopyChanged = async ({ value }) => {
    try {
      setIsSaving(true);
      const encodedText = encodeURIComponent(value);
      await apiUpdateCampaign({
        userID: userData._id,
        token: getToken(),
        id: activeCampaign._id,
        listingID: activeCampaign.listingID,
        adCopy: encodedText,
        name: campaignName
      });
      onUserDataChange({
        key: "campaigns",
        value: userData.campaigns.map(campaign =>
          campaign._id === activeCampaign._id
            ? {
                ...campaign,
                adCopy: encodedText
              }
            : campaign
        )
      });
      setIsSaving(false);
    } catch (error) {
      onStateChange({
        appKey: "messages",
        newState: [
          ...appState.messages,
          {
            type: "error",
            id: "ad-copy-error",
            message: error.message
          }
        ]
      });
    }
  };

  const handleSaveKeywords = async () => {
    try {
      await apiUpdateAdKeywords({
        token: getToken(),
        keywords
      });
      onStateChange({
        message: {
          type: "success",
          message: "Your keywords were saved."
        }
      });
    } catch (error) {
      onStateChange({
        appKey: "messages",
        newState: [
          ...appState.messages,
          {
            type: "error",
            id: "keyword-save-error",
            message: error.message
          }
        ]
      });
    }
  };

  const handleAddKeywordsToCopy = () => {
    if (adCopy.includes("-##-")) return;
    const keywordCopy = keywords.join(" ");
    setAdCopy(
      prev => `${prev}

-##-
${keywordCopy}`
    );
  };

  return (
    <section id="ad-wizard" className="flex-center-column w100percent">
      <div className="abs-top-left-1rem">
        <button
          className="action-button blue-text"
          onClick={() =>
            onStateChange({
              appKey: "ad-wizard",
              newState: {
                ...appState["ad-wizard"],
                panel: "ads"
              }
            })
          }
        >
          <ArrowBackIcon></ArrowBackIcon>back
        </button>
      </div>
      <div className="box-content padding-1rem flex-row-space-between w70">
        {!activeCampaign ? (
          <>
            <PptSelection
              id="ad-wiz-listing-select"
              items={userData.listings.map(listing => listing.uniqueName)}
              onChange={selection => {
                setSelectedListing(
                  userData.listings.find(
                    listing => listing.uniqueName === selection
                  )
                );
                setCampaignName(selection);
              }}
              selection={selectedListing ? selectedListing.uniqueName : null}
              label="Listing"
              className="w20rem"
            ></PptSelection>
            <PptInput
              labelText="Campaign Name"
              value={campaignName}
              onChange={e => setCampaignName(e.target.value)}
              className="w16rem"
              placeholder="Name your new ad campaign..."
            ></PptInput>
            <button
              className="small-button green-text"
              onClick={handleCreateCampaign}
            >
              Create Campaign
            </button>
          </>
        ) : (
          <>
            <h3>Campaign: {campaignName}</h3>
            <h3>Selected Listing: {selectedListing.uniqueName}</h3>
            <button
              className="small-button green-text flex-row-center"
              onClick={handleClearCampaign}
            >
              <ClearIcon></ClearIcon>
              Clear
            </button>
          </>
        )}
      </div>
      {activeCampaign ? (
        <>
          <div className="flex-center-column w100">
            {smsLeadMagnet ? (
              <SimpleLeadMagnetCard
                _id={smsLeadMagnet._id}
                code={smsLeadMagnet.code}
                listingName={selectedListing.uniqueName}
                phoneNumber={smsLeadMagnet.phoneNumber}
                responseText={smsLeadMagnet.responseText}
                onResponseTextChange={change => {
                  console.log(change);
                }}
                lmType={smsLeadMagnet.lmType}
              ></SimpleLeadMagnetCard>
            ) : (
              <div className="margin1rem-top box-content flex-center-column padding-1rem">
                <h3>This SMS Lead Magnet has been deleted.</h3>
              </div>
            )}
            {voiceLeadMagnet ? (
              <SimpleLeadMagnetCard
                _id={voiceLeadMagnet._id}
                code={voiceLeadMagnet.code}
                listingName={selectedListing.uniqueName}
                phoneNumber={voiceLeadMagnet.phoneNumber}
                responseText={voiceLeadMagnet.responseText}
                onResponseTextChange={change => {
                  console.log(change);
                }}
                lmType={voiceLeadMagnet.lmType}
              ></SimpleLeadMagnetCard>
            ) : (
              <div className="margin1rem-top box-content flex-center-column padding-1rem">
                <h3>This Voice Lead Magnet has been deleted.</h3>
              </div>
            )}
          </div>
          <div className="box-content flex-center-column padding-1rem margin1rem-top w70percent">
            <h3>Keywords</h3>
            <span className="blue-text text-small w42rem">
              Use Keywords for Craigslist ads. Pipeline Pro Tip: Add local
              neighborhood names and save your keywords to use across different
              ads.
            </span>
            {editingKeywords ? (
              <Keywords
                tags={keywords}
                onAdd={tag => {
                  setKeywords(prev =>
                    prev.includes(tag) ? prev : [...prev, tag]
                  );
                }}
                onDelete={tag => {
                  setKeywords(prev => prev.filter(t => t !== tag));
                }}
              ></Keywords>
            ) : null}
            <button
              className="small-button abs-top-right green-text"
              onClick={() => setEditingKeywords(prev => !prev)}
            >
              Edit Keywords
            </button>
            <span className="abs-lower-right">
              {editingKeywords ? (
                <button
                  className="small-button blue-text margin-1rem-right"
                  onClick={handleSaveKeywords}
                >
                  Save These Keywords
                </button>
              ) : null}
              <button
                className="small-button  green-text"
                onClick={handleAddKeywordsToCopy}
              >
                Add Keywords to Copy
              </button>
            </span>
          </div>
          <div className="flex-row-space-around w100 margin1rem-top">
            <div
              id="photo-download"
              className="box-content box-content-border h10rem flex-center-column padding-1rem"
            >
              <h3 className="dark-text">Photo Download</h3>
              <PhotoDownload
                className="flex-row-space-between small-button dark-text"
                clickedClassName="flex-row-space-between small-button green-text"
                linkIcon={() => <SaveAltIcon></SaveAltIcon>}
                downloadedIcon={() => <CheckCircleIcon></CheckCircleIcon>}
                filenameRoot={selectedListing.uniqueName}
                s3urls={selectedListing.images}
                onError={error =>
                  onStateChange({
                    appKey: "messages",
                    newState: [
                      ...appState.messages,
                      {
                        type: "error",
                        id: "photo-download-error",
                        message: error.message
                      }
                    ]
                  })
                }
              ></PhotoDownload>
            </div>
            <AutosaveTextArea
              text={adCopy}
              className="box-content w40percent"
              title="Ad Copy"
              rows={7}
              onTextChange={handleAdCopyChanged}
              isSaving={isSaving}
              clickToCopy={true}
            ></AutosaveTextArea>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default AdWizard;
