import apiCreateCredentials from "./api/apiCreateCredentials";
import apiCreateUser from "./api/apiCreateUser";
import apiConnectCredentials from "./api/apiConnectCredentials";
import { processResponse } from "./tokenManager";

const signupSubmit = async data => {
  const {
    email,
    password,
    firstName,
    lastName,
    phone,
    company,
    website,
    zip
  } = data;

  if (password.length < 8)
    return {
      error: true,
      message: "Passwords must be 8 characters."
    };
  if (password.length > 255)
    return {
      error: true,
      message: "Passwords cannot be over 255 characters."
    };
  // todo input validation

  try {
    await apiCreateCredentials({ email, password });
    const userResult = await apiCreateUser({
      email,
      website,
      firstName,
      lastName,
      phone,
      zip,
      company
    });
    const { payload } = userResult;
    const connectionResult = await apiConnectCredentials({
      email,
      token: payload
    });
    return processResponse(connectionResult);
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export { signupSubmit };
