import React from "react";

const TwilioConnectButton = ({ token, onChildWindowOpen }) => {
  const handleClick = () => {
    const childWindow = window.open(
      `https://www.twilio.com/authorize/CNbba10c259121ff6e4edac7f3885e7e49?state=${token}`,
      "Connect to Twilio...",
      "height=500,width=1000,menubar=0,toolbar=0,location=0"
    );
    onChildWindowOpen(childWindow);
  };

  return <button id="twilio-connect-button" onClick={handleClick}></button>;
};

export default TwilioConnectButton;
