import ZillowImporter from "./ZillowImporter";
import GoogleMapsImporter from "./GoogleMapsImporter";
import React from "react";

const importActionOptions = [
  {
    name: "Zillow",
    key: "zillow",
    component: props => <ZillowImporter {...props}></ZillowImporter>
  },
  {
    name: "Google Maps",
    key: "googlemaps",
    component: props => <GoogleMapsImporter {...props}></GoogleMapsImporter>
  }
];

export { importActionOptions };
