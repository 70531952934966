import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiUpdateCampaign = async ({
  id,
  token,
  userID,
  listingID,
  adCopy,
  name
}) => {
  const mutation = `mutation {
    data: updateCampaign(id:"${id}", update:{
      userID:"${userID}"
      listingID:"${listingID}"
      adCopy:"${adCopy}"
      name: "${name}"
    }){
      _id
      adCopy
      name
    }
  }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiUpdateCampaign;
