import apiAddress from "./apiAddress";

const apiGenerateTwilioLM = async ({
  token,
  userID,
  listingID,
  responseText,
  notify: { email, sms },
  lmType
}) => {
  const mutation = `mutation {
        generateTwilioLeadMagnet(data:{
          userID:"${userID}"
          ${listingID ? `listingID:"${listingID}"` : ""}
          responseText:"${responseText}"
          notify:{ 
            email:"${email}"
            sms:"${sms}"
          }
          lmType:"${lmType}"
        }) {
          _id
          phoneNumber
          code
          userID
          leads
          listingID
          dateCreated
          responseText
          notify {
            email
            sms
          }
          lmType
        }
      }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query: mutation
      })
    });
    const json = await result.json();
    return json.data.generateTwilioLeadMagnet;
  } catch (error) {
    throw error;
  }
};

export default apiGenerateTwilioLM;
