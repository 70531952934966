import apiAddress from "./apiAddress";
import { fetchWithToken } from "./apiFetch";

const apiGenerateCampaign = async ({
  token,
  userID,
  listingID,
  adCopy,
  name
}) => {
  const mutation = `mutation {
        data: generateCampaign(data:{
          userID:"${userID}"
          listingID:"${listingID}"
          adCopy:"${encodeURIComponent(adCopy)}"
          name:"${name}"
        }){
          _id
          userID
          listingID
          smsLeadMagnet {
            _id
            phoneNumber
            code
            responseText
            listingID
            lmType
            dateCreated
            leads
          }
          voiceLeadMagnet {
            _id
            phoneNumber
            code
            responseText
            listingID
            lmType
            dateCreated
            leads
          }
          name
          adCopy
          adKeywords
          dateCreated
        }
      }`;

  try {
    const result = await fetchWithToken({
      token,
      address: apiAddress(),
      query: mutation
    });
    return result.data;
  } catch (error) {
    console.log(error);
    throw new Error(
      "There was a problem generating a new campaign, please try again later."
    );
  }
};

export default apiGenerateCampaign;
