import React from "react";
import PptCheckbox from "../components/PptCheckbox";
import PptSelection from "../components/PptSelection";
import PptInput from "../components/PptInput";
import themes from "../helpers/themes";
import ThemeColors from "./ThemeColors";

const Settings = ({ formData, onFormChange, onFormSubmit }) => {
  return (
    <div className="flex-row-space-evenly padding-1rem borderbox w100 padding-1rem flex1">
      <div className="flex-center-column">
        <span className="text-small">New Lead Notifications</span>
        <PptCheckbox
          name="notify-email"
          onChange={e =>
            onFormChange({
              key: "settings",
              value: {
                ...formData.settings,
                notifyEmail: e.target.checked
              }
            })
          }
          labelText={formData.email ? formData.email : "email"}
          isChecked={formData.settings.notifyEmail}
          className="w16rem"
        ></PptCheckbox>
        <PptCheckbox
          name="notify-sms"
          onChange={e =>
            onFormChange({
              key: "settings",
              value: {
                ...formData.settings,
                notifySms: e.target.checked
              }
            })
          }
          labelText={formData.phone ? formData.phone : "sms"}
          isChecked={formData.settings.notifySms}
          className="w16rem"
        ></PptCheckbox>
      </div>
      <div className="flex-center-column">
        <PptSelection
          label="Single Property & Web Theme"
          className="w16rem"
          items={themes.map(theme => theme.name)}
          onChange={selection =>
            onFormChange({
              key: "settings",
              value: {
                ...formData.settings,
                theme: selection
              }
            })
          }
          selection={formData.settings.theme}
        ></PptSelection>
        <span className="margin1rem-top text-small">
          Selected Theme Colors:
        </span>
        <ThemeColors
          themeName={formData.settings.theme}
          className="w16rem"
        ></ThemeColors>
      </div>
      <div className="flex-center-column">
        <PptInput
          labelText="Monthly Lead Goal"
          value={formData.settings.monthlyLeadGoal}
          placeholder="# leads"
          type="number"
          className="w10rem"
          onChange={e =>
            onFormChange({
              key: "settings",
              value: {
                ...formData.settings,
                monthlyLeadGoal: e.target.value
              }
            })
          }
        ></PptInput>
        <PptCheckbox
          name="licensed-realtor"
          onChange={e =>
            onFormChange({
              key: "settings",
              value: {
                ...formData.settings,
                licensedRealtor: e.target.checked
              }
            })
          }
          labelText={"Licensed Realtor"}
          isChecked={formData.settings.licensedRealtor}
          className="w16rem"
        ></PptCheckbox>
      </div>
    </div>
  );
};

export default Settings;
