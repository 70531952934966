import React from "react";

const SidebarNavItem = ({ activeTab, navLink, onClick, children }) => (
  <h3
    className={
      activeTab === navLink
        ? "sidebar-nav-item sidebar-nav-active"
        : "sidebar-nav-item"
    }
    onClick={() => onClick(navLink)}
  >
    {children}
  </h3>
);

export default SidebarNavItem;
