import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { getToken } from "../../../helpers/tokenManager";
import { apiPostSignedS3Url } from "../../../helpers/api/apiSignedS3Url";
import { s3PostFile } from "../../../helpers/s3PostFile";
import { createGuid } from "../../../helpers/guidHelper";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import "../../../components/dropzone.css";

const ListingPhotos = ({ children, userID, keyPrefix, onNewUpload }) => {
  const onDrop = useCallback(
    async acceptedFiles => {
      const token = getToken();

      for (const file of acceptedFiles) {
        const key = createGuid() + "/" + file.name;
        const signedUrl = await apiPostSignedS3Url({
          userID,
          token,
          keyPrefix,
          key,
          contentType: file.type
        });
        console.log(signedUrl);
        console.log(file);
        const result = await s3PostFile({ file, signedUrl });
        if (onNewUpload) {
          onNewUpload({ key: result });
        }
        console.log(result);
      }
    },
    [userID, keyPrefix, onNewUpload]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialogue
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    noClick: true
  });

  return (
    <div
      onClick={() => openFileDialogue()}
      className="box-content flex-center-column padding-1rem photo-container margin1rem"
    >
      <div
        className={
          isDragActive
            ? "dropzone-photos dropzone-active position-rel inset-box border-5px-dashed-blue"
            : "dropzone-photos position-rel inset-box border-5px-transparent"
        }
        {...getRootProps()}
      >
        <input {...getInputProps()}></input>
        <div className="flex-center-column dropzone-display h100">
          <AddPhotoAlternateIcon></AddPhotoAlternateIcon>
          <span className="dropzone-text">
            drag and drop image files here to upload
          </span>
        </div>
        {children}
      </div>
      <button onClick={() => openFileDialogue()}>open</button>
    </div>
  );
};

export default ListingPhotos;
