import apiAddress from "./apiAddress";

const apiCheckWebUniqueName = async ({ name, token }) => {
  const query = `{
          checkWebUniqueName(uniqueName:"${name}"){
            error
            message
            code
          }
        }`;

  try {
    const result = await fetch(apiAddress(), {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        query
      })
    });
    const json = await result.json();
    return json.data.checkWebUniqueName;
  } catch (error) {
    throw error;
  }
};

export default apiCheckWebUniqueName;
