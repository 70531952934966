import React, { useState, useEffect } from "react";
import AgentProfilePic from "./AgentProfilePic";
import UniqueName from "./UniqueName";
import CallToAction from "./CallToAction";
import AgentStory from "./AgentStory";
import { apiPostSignedS3Url } from "../../helpers/api/apiSignedS3Url";
import apiUpdateAgentPic from "../../helpers/api/apiUpdateAgentPic";
import apiUpdateAgentLogo from "../../helpers/api/apiUpdateAgentLogo";
import apiCreateWebLM from "../../helpers/api/apiCreateWebLM";
import apiUpdateWebLM from "../../helpers/api/apiUpdateWebLM";
import apiCheckWebUniqueName from "../../helpers/api/apiCheckWebUniqueName";
import { websiteCopy as defaultWebCopy } from "../../helpers/defaultCopy";
import { s3PostFile } from "../../helpers/s3PostFile";
import { createGuid } from "../../helpers/guidHelper";
import { getToken } from "../../helpers/tokenManager";
import DropUploadS3 from "../../components/DropUploadS3";

import "./webLeads.css";

const WebLeads = ({ userData, onUserDataChange, appState, onStateChange }) => {
  const [websiteData, setWebsiteData] = useState(null);
  const [createNew, setCreateNew] = useState(true);
  const [uniqueIsValid, setUniqueIsValid] = useState(true);
  const [titleStorySaving, setTitleStorySaving] = useState(false);

  useEffect(() => {
    const getWebsiteData = async () => {
      if (!userData) return;
      if (userData.webLMs.length) {
        setWebsiteData({
          ...userData.webLMs[0],
          callToAction: decodeURIComponent(userData.webLMs[0].callToAction),
          callToAction2: decodeURIComponent(userData.webLMs[0].callToAction2),
          titleStory: decodeURIComponent(userData.webLMs[0].titleStory)
        });
        setCreateNew(false);
      } else {
        setCreateNew(true);
      }
    };
    getWebsiteData();
  }, [userData]);

  const handleCTAChange = e => {
    const value = e.target.value;
    setWebsiteData(prev => ({
      ...prev,
      callToAction: value
    }));
  };

  const handleCTAChange2 = e => {
    const value = e.target.value;
    setWebsiteData(prev => ({
      ...prev,
      callToAction2: value
    }));
  };

  const handleNewUpload = async file => {
    try {
      const key = createGuid() + "/" + file.name;
      const signedPostUrl = await apiPostSignedS3Url({
        userID: userData._id,
        token: getToken(),
        keyPrefix: "agentProfilePic",
        key,
        contentType: file.type
      });
      //console.log(signedPostUrl);
      const s3Key = await s3PostFile({ file, signedUrl: signedPostUrl });
      // console.log(s3Key);
      // const signedUrl = await getS3Url({ key: s3Key });
      // console.log(signedUrl);
      await apiUpdateAgentPic({
        key: s3Key,
        token: getToken()
      });
      onUserDataChange({
        key: "agentProfilePic",
        value: {
          bucketName: "ppt",
          keyName: s3Key
        }
      });
    } catch (error) {
      onStateChange({
        message: {
          message: error.message,
          type: "error",
          id: "agent-pic-error"
        }
      });
    }
  };

  const handleLogoUpload = async file => {
    try {
      const key = createGuid() + "/" + file.name;
      const signedPostUrl = await apiPostSignedS3Url({
        userID: userData._id,
        token: getToken(),
        keyPrefix: "agentLogo",
        key,
        contentType: file.type
      });
      const s3Key = await s3PostFile({ file, signedUrl: signedPostUrl });
      //const signedUrl = await getS3Url({ key: s3Key });
      await apiUpdateAgentLogo({
        key: s3Key,
        token: getToken()
      });
      onUserDataChange({
        key: "agentLogo",
        value: {
          bucketName: "ppt",
          keyName: s3Key
        }
      });
    } catch (error) {
      onStateChange({
        message: {
          message: error.message,
          type: "error",
          id: "agent-logo-error"
        }
      });
    }
  };

  const handleUniqueNameUpdate = async ({ uniqueName }) => {
    if (websiteData && uniqueName === websiteData.uniqueName) {
      setUniqueIsValid(true);
      return;
    }

    const { error } = await apiCheckWebUniqueName({
      token: getToken(),
      name: uniqueName
    });
    setUniqueIsValid(!error);
  };

  const handleUniqueNameSubmit = async ({ uniqueName }) => {
    console.log(uniqueName);
    try {
      if (!uniqueIsValid)
        throw new Error("You must use a unique website name!");

      if (!websiteData) {
        const newWebLM = {
          uniqueName,
          callToAction: defaultWebCopy.callToAction,
          callToAction2: defaultWebCopy.callToAction2,
          title: defaultWebCopy.title,
          titleStory: defaultWebCopy.titleStory({
            firstName: userData.contact.firstName,
            lastName: userData.contact.lastName
          }),
          theme: defaultWebCopy.theme
        };

        const result = await apiCreateWebLM({
          token: getToken(),
          userID: userData._id,
          ...newWebLM
        });
        if (result.error) throw new Error("That name is already in use.");
        onUserDataChange({
          key: "webLMs",
          value: [
            {
              ...newWebLM,
              titleStory: encodeURIComponent(newWebLM.titleStory),
              _id: result.id
            }
          ]
        });
      } else {
        const result = await apiUpdateWebLM({
          token: getToken(),
          ...websiteData,
          userID: userData._id,
          id: websiteData._id,
          uniqueName
        });
        if (result.error) throw new Error(result.message);
        onUserDataChange({
          key: "webLMs",
          value: [
            {
              ...websiteData,
              callToAction: encodeURIComponent(websiteData.callToAction),
              titleStory: encodeURIComponent(websiteData.titleStory)
            }
          ]
        });
      }
    } catch (error) {
      onStateChange({
        message: {
          message: error.message,
          type: "error",
          id: "web-leads-error"
        }
      });
    }
  };

  const submitChanges = async () => {
    try {
      const result = await apiUpdateWebLM({
        token: getToken(),
        ...websiteData,
        userID: userData._id,
        id: websiteData._id
      });
      if (result.error) throw new Error(result.message);
      onUserDataChange({
        key: "webLMs",
        value: [
          {
            ...websiteData,
            callToAction: encodeURIComponent(websiteData.callToAction),
            titleStory: encodeURIComponent(websiteData.titleStory)
          }
        ]
      });
    } catch (error) {
      onStateChange({
        message: {
          message: error.message,
          type: "error",
          id: "web-leads-error"
        }
      });
    }
  };

  const handleTitleStoryChange = async ({ titleStory }) => {
    try {
      setTitleStorySaving(true);
      const result = await apiUpdateWebLM({
        token: getToken(),
        ...websiteData,
        userID: userData._id,
        id: websiteData._id,
        titleStory
      });
      if (result.error) throw new Error(result.message);
      onUserDataChange({
        key: ["webLMs"],
        value: [
          {
            ...websiteData,
            titleStory: encodeURIComponent(titleStory)
          }
        ]
      });
      setTitleStorySaving(false);
    } catch (error) {
      onStateChange({
        message: error.message,
        type: "error",
        id: "web-leads-error"
      });
      setTitleStorySaving(false);
    }
  };

  return (
    <section id="website-editor" className="flex-center-column w100percent">
      <UniqueName
        uniqueName={websiteData ? websiteData.uniqueName : ""}
        onUniqueNameChange={handleUniqueNameUpdate}
        hostAddress={defaultWebCopy.hostAddress}
        isValid={uniqueIsValid}
        createNew={createNew}
        onSubmit={handleUniqueNameSubmit}
      ></UniqueName>
      {websiteData ? (
        <>
          <CallToAction
            callToAction={websiteData.callToAction}
            callToAction2={websiteData.callToAction2}
            onChange={handleCTAChange}
            onChange2={handleCTAChange2}
            onSubmit={() => submitChanges()}
          ></CallToAction>
          <div className="flex-row-space-between w42rem">
            <AgentProfilePic
              agentProfilePic={userData.agentProfilePic}
              onNewUpload={handleNewUpload}
            ></AgentProfilePic>
            <DropUploadS3
              s3Item={userData.agentLogo}
              onNewUpload={handleLogoUpload}
              text="Upload Your Logo"
              accept="image/jpeg, image/png, image/svg+xml"
            ></DropUploadS3>
          </div>
          <AgentStory
            titleStory={websiteData.titleStory}
            onTitleStoryChange={handleTitleStoryChange}
            isSaving={titleStorySaving}
          ></AgentStory>
        </>
      ) : null}
    </section>
  );
};

export default WebLeads;
